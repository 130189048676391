<script lang="ts"></script>
<template>
  <div class="navigation-wrap bg-light start-header start-style">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <nav class="navbar navbar-expand-md navbar-light">
            <a class="navbar-brand" href="/"
              ><img alt="" src="assets/img/Progressive-logo.png"
            /></a>
            <button
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              class="navbar-toggler"
              data-target="#navbarSupportedContent"
              data-toggle="collapse"
              type="button"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div id="navbarSupportedContent" class="collapse navbar-collapse">
              <ul class="navbar-nav ml-auto py-4 py-md-0">
                <li class="nav-item pl-4 pl-md-0 ml-0 ml-md-4">
                  <router-link class="nav-link" :to="{ name: 'home' }">
                    {{ $t("nav.home") }}
                  </router-link>
                </li>
                <li class="nav-item pl-4 pl-md-0 ml-0 ml-md-4">
                  <router-link class="nav-link" :to="{ name: 'about-us' }">
                    {{ $t("nav.aboutUs") }}
                  </router-link>
                </li>
                <li class="nav-item pl-4 pl-md-0 ml-0 ml-md-4">
                  <router-link class="nav-link" :to="{ name: 'patient' }">
                    {{ $t("nav.patients") }}
                  </router-link>
                </li>
                <li class="nav-item pl-4 pl-md-0 ml-0 ml-md-4">
                  <router-link class="nav-link" :to="{ name: 'doctor' }">
                    {{ $t("nav.doctor") }}
                  </router-link>
                </li>
                <li class="nav-item pl-4 pl-md-0 ml-0 ml-md-4">
                  <router-link class="nav-link" :to="{ name: 'contact' }">
                    {{ $t("nav.contact") }}
                  </router-link>
                </li>
                <li class="nav-item pl-4 pl-md-0 ml-0 ml-md-4">
                  <a
                    class="btn btn-light btnline"
                    target="_blank"
                    href="https://login.progressivealigners.com/"
                  >
                    {{ $t("nav.doctorPortal") }}
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
/* Adjustments to navbar-nav alignment */
.navbar-nav {
  display: flex;
  justify-content: flex-end; /* This will align the nav items to the right */
  flex-grow: 1; /* This ensures that the navbar-nav will expand to fill available space if needed */
}

.nav-item {
  padding-left: 0.5rem; /* Adjust left padding for better spacing */
  padding-right: 0.5rem; /* Adjust right padding for better spacing */
  /* The margins have been removed to use padding for spacing */
  transition: all 200ms linear;
}
</style>
