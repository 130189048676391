<script setup lang="ts">
import { useI18n } from "vue-i18n";

const { locale } = useI18n();

const handleLanguageChange = () => {
  console.log("handleLanguageChange");

  const element = document.getElementById("lang-change") as HTMLSelectElement;
  if (element.value) {
    locale.value = element.value;
  }
};
</script>
<template>
  <footer class="new_footer_area bg_color">
    <div class="new_footer_top">
      <div class="container">
        <div class="row">
          <div class="col-6 col-lg-3 col-md-6">
            <div
              class="f_widget about-widget pl_70 wow fadeInLeft"
              data-wow-delay="0.4s"
              style="
                visibility: visible;
                animation-delay: 0.4s;
                animation-name: fadeInLeft;
              "
            >
              <h3 class="f-title f_600 t_color f_size_18">
                {{ $t("footerSection.corporate.title") }}
              </h3>
              <ul class="list-unstyled f_list">
                <li>
                  <router-link :to="{ name: 'home' }">{{
                    $t("footerSection.corporate.home")
                  }}</router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'about-us' }">{{
                    $t("footerSection.corporate.aboutUs")
                  }}</router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'patient' }">{{
                    $t("footerSection.corporate.patient")
                  }}</router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'doctor' }">{{
                    $t("footerSection.corporate.doctor")
                  }}</router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'faq' }">{{
                    $t("footerSection.corporate.faq")
                  }}</router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-6 col-lg-3 col-md-6">
            <div
              class="f_widget about-widget pl_70 wow fadeInLeft"
              data-wow-delay="0.6s"
              style="
                visibility: visible;
                animation-delay: 0.6s;
                animation-name: fadeInLeft;
              "
            >
              <h3 class="f-title f_600 t_color f_size_18">
                {{ $t("footerSection.service.title") }}
              </h3>
              <ul class="list-unstyled f_list">
                <li>
                  <router-link :to="{ name: 'contact' }">{{
                    $t("footerSection.service.contactUs")
                  }}</router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'find-doctor' }">{{
                    $t("footerSection.service.findDoctors")
                  }}</router-link>
                </li>
                <li>
                  <router-link
                    target="_blank"
                    :to="{ name: 'doctors-portal' }"
                    >{{ $t("footerSection.service.doctorPortal") }}</router-link
                  >
                </li>
                <li>
                  <router-link :to="{ name: 'privacy-policy' }">{{
                    $t("footerSection.service.privacyPolicy")
                  }}</router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div
              class="f_widget company_widget wow fadeInLeft"
              data-wow-delay="0.2s"
              style="
                visibility: visible;
                animation-delay: 0.2s;
                animation-name: fadeInLeft;
              "
            >
              <h3 class="f-title f_600 t_color f_size_18">
                {{ $t("footerSection.getInTouch.title") }}
              </h3>
              <p>
                {{ $t("footerSection.getInTouch.description") }}
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div
              class="f_widget social-widget pl_70 wow fadeInLeft"
              data-wow-delay="0.8s"
              style="
                visibility: visible;
                animation-delay: 0.8s;
                animation-name: fadeInLeft;
              "
            >
              <h3 class="f-title f_600 t_color f_size_18">
                {{ $t("footerSection.translation.title") }}
              </h3>
              <div>
                <select
                  class="form-control"
                  @change="handleLanguageChange"
                  id="lang-change"
                >
                  <option value="en" selected>English</option>
                  <option value="es">Spanish</option>
                </select>
              </div>
              <h3 class="f-title f_600 t_color f_size_18">
                {{ $t("footerSection.translation.teamSolutions") }}
              </h3>
              <div class="f_social_icon mb-5">
                <a
                  href="https://www.facebook.com/ProgressiveOrthodontics/"
                  class="fa fa-facebook"
                ></a
                ><a
                  href="https://twitter.com/POSortho"
                  class="fa fa-twitter"
                ></a
                ><a
                  href="https://www.linkedin.com/company/progressive-orthodontic-seminars"
                  class="fa fa-linkedin"
                ></a
                ><a
                  href="https://www.instagram.com/pos_ortho/"
                  class="fa fa-instagram"
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer_bg">
        <div class="footer_bg_one"></div>
        <div class="footer_bg_two"></div>
      </div>
    </div>
    <div class="footer_bottom">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6 col-sm-7">
            <p class="f_400 mb-2 mt-2">
              {{ $t("footerSection.copyright") }}
            </p>
          </div>
          <div class="col-lg-6 col-sm-5 mb-2 mt-2 text-right">
            <p>
              <a href="https://smilestream.com/pos-ortho" target="_blank"
                >www.posortho.com</a
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<style scoped lang="scss">
.google-translate-select {
  z-index: 9999;
}
</style>
