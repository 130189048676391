const messages = {
  en: {
    nav: {
      home: "Home",
      aboutUs: "About Us",
      patients: "Patients",
      doctor: "Doctor",
      contact: "Contact",
      doctorPortal: "Doctor Portal",
    },
    home: {
      header: "Brightening Days One Smile at a Time",
      subheader: "Unlocking happiness - Illuminated Smiles!",
      aligners: {
        title: "Redefine your confidence.",
        subtitle: "The path to your dream smile.",
        feature1Title: "Removable Convenience",
        feature1Text:
          "Unlike traditional braces, our Aligners are removable. Meaning you have the freedom to continue enjoying your favorite foods without restrictions all while maintaining your oral hygiene routine.",
        feature2Title: "Comfort",
        feature2Text:
          "Our Aligners are custom made with exceptional craftsmanship. Which means smooth edges, a full 360-degree fitting, and most of all no irritation for you.",
        feature3Title: "Virtually Invisible",
        feature3Text:
          "Our Aligners are made from transparent BPA free material, making them difficult for others to notice. This allows you to go about your daily life with confidence in any environment.",
        feature4Title: "Effective",
        feature4Text:
          "Our Aligners are made using advanced 3D technology, allowing for a more precise vision of tooth movements, thus resulting in a more efficient and predictable treatment journey for you.",
      },
      alignersProcess: {
        consultationDescription:
          "Schedule an appointment with a certified dental professional for a thorough and comprehensive oral examination. This examination will involve conducting a complete oral examination, digital scans of your teeth, photographs, and obtaining X-rays of your mouth.",
        consultationTitle: "Consultation",

        treatmentPlanDescription:
          "Based on your digital scans, the orthodontist or dentist will create a customized treatment plan for you. Using specialized software, they will simulate the movement of your teeth throughout the treatment process, so that we can create custom Aligners that fit your mouth precisely.",
        treatmentPlanTitle: "Treatment Plan",

        alignersDesignDescription:
          "Your treatment plan is sent to our laboratory where your custom Aligners are designed. Using advanced technology, our Aligners are created from a clear, BPA-free plastic material.",
        alignersDesignTitle: "Aligners Design",

        treatmentProcessDescription:
          "After you see your doctor, you'll receive a set of Aligners to wear. Each Aligner has a specific duration, and you should wear them for about 20-22 hours per day. Take them out only when brushing your teeth, eating, or drinking hot liquids.",
        treatmentProcessTitle: "Treatment Process",

        progressCheckupsDescription:
          "Throughout your treatment process, you will have periodic checkup appointments with your doctor. These appointments allow your doctor to monitor your progress, make any necessary adjustments, and provide you with the next set of Aligners.",
        progressCheckupsTitle: "Progress Checkups",

        newSmileDescription:
          "Once you’ve completed your treatment, your teeth should be in the desired position. However, to maintain your results, it's recommended to wear retainers so that you optimize your new confident smile.",
        newSmileTitle: "Your New Smile",
      },
      smilesSection: {
        title: "Radiant Smiles, Daily Delight!",
        description:
          "We believe that achieving a stunning smile should be accessible to everyone.",
        feature1: {
          title: "Superior Results",
          text: "Customers choose us because we consistently deliver outstanding results when it comes to teeth Aligners.",
        },
        feature2: {
          title: "Convenience and Comfort",
          text: "Enjoy the freedom of removable Aligners with no food restrictions and a comfortable fit.",
        },
        feature3: {
          title: "Personalized Care",
          text: "We understand that every smile is unique, which is why we provide a personalized approach to Aligners treatment.",
        },
      },
      consultationSection: {
        heading: "Ready for a consultation?",
        description:
          "We can help you find a Progressive Aligners doctor near you.",
        findDoctorButton: "Find a doctor",
      },
      testimonialsSection: {
        title: "WHAT OUR CLIENTS SAY",
        subTitle: "TESTIMONIALS",
        testimonial1: {
          text: "Progressive Aligners has exceeded my expectations in terms of satisfaction and treatment outcomes. With its clear Aligners, patients experience minimal disruptions to their daily lives while achieving their desired smile.",
          name: "Jennifer Adams",
        },
        testimonial2: {
          text: "Having tried various orthodontic products, I can confidently say that Progressive Aligners stands out from the rest. Its advanced technology and customized treatment plans provide predictable and efficient tooth movement.",
          name: "Judi Roberts",
        },
        testimonial3: {
          text: "The ease of use, comfort, and effectiveness of Progressive Aligners make it an excellent choice for straightening teeth. I highly recommend it.",
          name: "Peter Stone",
        },
      },
    },
    patient: {
      alignersInfoSection: {
        candidateQuestion: "Am I a candidate?",
        transformSmile: "Transform Your Smile Today!",
        firstStepTitle:
          "Take the first step towards achieving your ideal smile.",
        firstStepDescription:
          "Contact your doctor today to schedule a consultation and explore the best treatment options for you. Don't wait any longer to begin your journey towards a healthy and confident smile.",
        rightForYouTitle: "Are Progressive aligners right for you?",
        smileDescription: "Which best describes your smile?",
        consultationTitle: "Ready for a consultation?",
        consultationDescription:
          "We can help you find a Progressive Aligners doctor near you.",
        findDoctorButton: "Find a doctor",
        getStartedButton: "Get Started",
      },
      alignersConditions: {
        crowding: {
          title: "Crowding",
          description:
            "Teeth crowding is a common orthodontic issue where there's not enough space for your teeth to fit properly. This can lead to misalignment or displacement over time. Treatment is crucial to prevent long-term harm to your teeth and gums.",
        },
        spacing: {
          title: "Spacing",
          description:
            "Spacing, the contrary of crowding, arises when there is an excessive amount of space within your mouth, leading to uneven spreading of your teeth. As a result, your teeth might shift to occupy the extra room, resulting in uneven gaps between them.",
        },
        crossbite: {
          title: "Crossbite",
          description:
            "A crossbite is a misalignment of dental arches where teeth close at an angle. In a posterior crossbite, upper back teeth rest inside the lower back teeth's arch. In an anterior crossbite, upper front teeth are positioned behind lower front teeth. It can affect single or multiple teeth simultaneously.",
        },
        openBite: {
          title: "Open Bite",
          description:
            "An open bite typically manifests when your posterior teeth make contact while biting, but your anterior teeth do not meet properly. This condition arises when the teeth tilt outward, causing a noticeable gap between the upper and lower front teeth.",
        },
        overbite: {
          title: "Overbite",
          description:
            "An overbite occurs when there is improper alignment of your teeth, resulting in the upper teeth protruding outward and overlapping the lower teeth.",
        },
        deepOverjet: {
          title: "Deep Overjet",
          description:
            "A condition where the upper front teeth extend excessively forward in relation to the lower front teeth. It results in a visible horizontal gap or space between the upper and lower teeth when the jaws are closed.",
        },
      },
      faqSection: {
        title: "Frequently Asked Questions",
        introduction:
          "Explore our FAQs for quick answers or contact our support team for assistance. We're here to help!",
        questions: {
          question1: {
            title: "What is Progressive Aligners treatment?",
            answer:
              "The process of wearing a series of clear, BPA free removable aligners that will gradually straighten your teeth. No traditional brackets and wires necessary, and none of the restrictions that come with metal braces.",
          },
          question2: {
            title: "Who are your aligners designed for?",
            answer:
              "Our aligners are an excellent option for Kids, Teens, and Adults who want to achieve a more desired smile.",
          },
          question3: {
            title:
              "What are Progressive Aligners made of and what do they look like?",
            answer:
              "Our clear aligners are made of elastic, BPA free, memory materials. Along with multi-layer light diffusion and an anti-reflective surface, that will make the aligner almost invisible.",
          },
          question4: {
            title: "How will Aligner treatment straighten my teeth?",
            answer:
              "Your Progressive Aligner Doctor will customize a digital treatment plan specifically outlining the precise tooth movements required. Subsequently, your Progressive aligners will be designed to apply the appropriate force at the precise locations and timings as specified in your plan. Throughout the process, your Progressive Aligners Doctor will closely monitor your progress and ensure the aligners fit properly, providing guidance and support at every stage.",
          },
          question5: {
            title:
              "What are the primary benefits of Progressive Aligners treatment?",
            answer:
              "Our clear aligners offer a nearly invisible treatment option! Making it difficult for others to notice that you're wearing them. Our aligners are made of a flexible patient-friendly material giving you the convenience of removing the aligners at any time.",
          },
          question6: {
            title:
              "Am I guaranteed to have straight teeth with Progressive Aligners treatment?",
            answer:
              "Each smile is one of one, which is why your aligner treatment starts with a consultation with one of our Progressive aligners doctors. During your consultation, your doctor will help you determine which treatment is best to achieve your ideal smile. While we cannot guarantee each particular outcome, most patients have achieved their desired smile goals.",
          },
          question7: {
            title: "What is the treatment process?",
            answer:
              "The first step is to schedule a consultation with one of our Progressive Aligners doctors. During your consultation your doctor will provide a comprehensive oral examination, complete with digital scans. Upon review of your digital scans, your doctor will create a tailored made treatment plan for you, and then send your plan to us to begin designing your Progressive Aligner. Upon returning to your doctor, you’ll be given a series of aligners, each designed to be worn for a specific duration. Your aligners should be worn 20 to 22 hours a day, only removing them before brushing, eating or drinking hot liquids. Throughout the course of your treatment, you’ll continue to meet with your doctor for periodic checkups until you achieve your desired smile.",
          },
          question8: {
            title: "How long does treatment take?",
            answer:
              "The duration of your treatment can vary based on the complexity of your case, but it is possible to complete it in as few as six months. However, you will begin noticing visible results in a short amount of time, showcasing the effectiveness of the treatment in delivering noticeable improvements to your teeth alignment.",
          },
          question9: {
            title: "What’s the best way to clean my aligners?",
            answer:
              "The best way to clean your aligners is by brushing them with mouthwash or an aligner cleaner and rinsing them in lukewarm water before placing them back into your mouth.",
          },
          question10: {
            title: "What should I do if I lose or break my aligner?",
            answer:
              "Contact your doctor immediately, so that they can order a new set of aligners according to your current treatment plan. This will keep you on track to achieve your desired smile.",
          },
          question11: {
            title:
              "Can I order replacement aligners from Progressive Aligners directly?",
            answer:
              "Unfortunately, you cannot at this time. All aligners must be ordered directly through your doctor.",
          },
        },
      },
      testimonialsSection: {
        title: "WHAT OUR CLIENTS SAY",
        subTitle: "TESTIMONIALS",
        testimonial1: {
          text: "Progressive Aligners has exceeded my expectations in terms of satisfaction and treatment outcomes. With its clear Aligners, patients experience minimal disruptions to their daily lives while achieving their desired smile.",
          name: "Jennifer Adams",
        },
        testimonial2: {
          text: "Having tried various orthodontic products, I can confidently say that Progressive Aligners stands out from the rest. Its advanced technology and customized treatment plans provide predictable and efficient tooth movement.",
          name: "Judi Roberts",
        },
        testimonial3: {
          text: "The ease of use, comfort, and effectiveness of Progressive Aligners make it an excellent choice for straightening teeth. I highly recommend it.",
          name: "Peter Stone",
        },
      },
    },
    about: {
      header: "About Us",
      subHeader: "We prioritize customer satisfaction above all else.",
      section1Header: "Orthodontics is our passion",
      section1Paragraph:
        "At Progressive Orthodontics, we are dedicated to offering a comprehensive orthodontic system that empowers dentists to deliver healthy and beautiful smiles to their patients. Since our establishment in 1984, we have consistently stayed at the forefront of technology to ensure exceptional service to our valued customers, who we proudly consider part of our POS family. Recognizing the evolving demands of today's world, we have invested years of research and development to meet those needs. Now, we are thrilled to introduce our very own aligner brand, Progressive Aligners. This venture has been a long time in the making, and we are excited to embark on the journey of brightening smiles one day at a time!",

      section2Header: "Let's meet (and exceed) the standard of care",
      section2Paragraph1:
        "We are passionate about dentistry and feel that our community can do better. Many in our industry don' t acknowledge that aligners are more than a cosmetic device. Driven by our unwavering passion for dentistry, we firmly believe in the potential for improvement within our community. Unfortunately, many in our industry fail to recognize that aligners serve a purpose beyond mere cosmetic enhancement. We urge you to read about the alarming",
      section2Paragraph1DrivenBy:
        "Driven by our unwavering passion for dentistry, we firmly believe in the potential for improvement within our community.",
      section2Paragraph2:
        "Aligner therapy is a trusted branch of orthodontics that demands the utmost seriousness for the sake of our patients' well-being. As a highly effective orthodontic tool, clear aligners possess the ability to correct malocclusions, align teeth, and significantly enhance long term oral health. It's crucial that clear aligner therapy is not limited to the diagnosis of a lab technician; rather it should be administered by a trained dentist who can ensure proper planning, treatment execution, and monitoring.",
      section2Paragraph3:
        "We firmly believe that every aligner provider should conduct thorough records, diagnose patients to ensure proper bite formation, document multiple treatment options, and establish a well-defined treatment plan. Our comprehensive education programs empower dentists to deliver the standard of care established by the American Board of Orthodontics (ABO) in a practical and efficient manner, benefiting everyone involved.",
      section2Paragraph3AndOur: "and our",
      section2Link1Text: "DIY Orthodontics Trend",
      section2Link2Text:
        "Click here for more information on our Selective Orthodontics program",
      section2Link3Text: "Aligner Essentials program",
      section3Header:
        "Progressive Aligners will revolutionize the way we view aligners.",
      section3Paragraph:
        "Motivated by a sense of responsibility to share our expertise, we've initiated a progressive movement. Through years of innovation, support from legislation, and the dedication of graduates who provide exceptional care, the field of dentistry, including orthodontics, has been transformed. As a result, dentists worldwide now have the opportunity to provide the most innovative aligner, so that their patients can embrace their beautiful smile and let it be a source of inspiration.",
    },
    doctor: {
      header: "The Aligner Designed for the GP!",
      subHeader: "Provide your patients the smile they've always dreamed of.",
      paragraph1:
        "Progressive Orthodontics has been at the forefront of GP Ortho for the past four decades, and we have leveraged our extensive experience to create a tailored aligner solution that perfectly suits your requirements. In collaboration with the industry's leading manufacturer, we customized their product to seamlessly integrate into the typical GP office environment. We developed an innovative educational program that equips you with the most up-to-date knowledge readily accessible to you. Additionally, we've introduced a groundbreaking COACHING program, ensuring that you have a mentor to guide you towards success with every case. Our pricing plans are meticulously crafted with your busy practice in mind, recognizing that aligners are just one facet of your professional responsibilities. Above all, our focus remains on you, the General Dentist, and we have meticulously designed every aspect of our product to cater to your specific needs.",

      ceoHeader: "CEO Miles McGann",
      ceoParagraph:
        "Our highly anticipated aligner launch marks a new era in orthodontic care, while offering a groundbreaking coaching program. Together, let's embrace the future of teeth alignment and create a world where everyone can confidently share their beautiful smiles.",

      drHeader: "Dr. Collins Harrell",
      drParagraph:
        "Our aligner product launch represents a significant leap forward in orthodontics, offering an innovative, comfortable, and effective solution for achieving a flawless smile. We're excited to embark on this journey, transforming smiles and instilling newfound confidence in patients worldwide.",
      progressiveAdvantage: {
        sectionTitle: "The Progressive Advantage",

        tabCoaching: "Coaching & Support",
        tabEducation: "Education",
        tabTieredPricing: "Tiered Pricing",
        tabSuperiorProduct: "Superior Product",
        tabCompanyExperience: "Company Experience",
        tabCaseStudies: "Case Studies",

        coachingContent: {
          header: "Coaching & Support",
          paragraph:
            "The Progressive Aligners clinical team is fully dedicated to providing you with unwavering support throughout every case, tailored to your specific needs. We have established three levels of support to guarantee that you receive the ideal amount of assistance. Every user of Progressive Aligners has the option to select a coach who will offer invaluable guidance, completely free of charge, to determine whether a case is suitable for you and address any basic inquiries. If you require further assistance in developing the perfect initial diagnosis and treatment plan, our consultants are readily available to help you embark on the journey with a flawless strategy (fee-based service). Should you desire comprehensive support throughout the entire case, encompassing all revisions, our Mentoring service ensures that you have an experienced expert guiding you to successful completion (fee-based service).",
        },

        educationContent: {
          header: "Education",
          paragraph:
            "We recognize the challenges faced by the busy General Practitioners in keeping up with the ever-evolving aligner industry. For this reason, we've curated a diverse range of courses available in multiple formats, thus enabling you to acquire the skills and knowledge necessary to utilize our aligners efficiently. Our courses are thoughtfully designed to accommodate your specific needs, offering flexibility and convenience. Additionally, we're developing a complimentary case series that will be readily accessible on our website, providing a valuable resource for continuous learning at your convenience.",
        },

        tieredPricingContent: {
          header: "Tiered Pricing",
          paragraph:
            "We acknowledge that Orthodontics represents just one facet of your busy practice, and we have meticulously tailored our pricing and discount programs to reflect this understanding. Our aim is to ensure that all our clients receive exceptional value, which is why we've strived to maintain highly competitive retail prices comparable to other major brands in the industry. Furthermore, our discount program is designed with attainable requirements specifically geared towards General Practitioners! We want you to begin at the level you rightfully deserve, so we evaluate and assign your appropriate level based on your case history over the past six months. Simply provide us with evidence of your case volume from any company or a combination of multiple companies within the past six months, and we'll align you at the corresponding level that matches your volume.",
        },

        superiorProductContent: {
          header: "Superior Product",
          paragraph:
            "We take pride in ensuring our product surpasses industry standards, setting a new benchmark for excellence. Along with assembling the best designers in the industry, we've incorporated cutting-edge design techniques that provide unmatched functionality and aesthetics for any patient. Furthermore, our user-friendly interface ensures seamless communication with our design team, enabling efficient and effortless collaboration at every step of your case.",
        },

        companyExperienceContent: {
          header: "Company Experience",
          paragraph:
            "With our legacy dating back to 1984, we've established ourselves as the foremost educational and mentoring program in GP Orthodontics. Our extensive experience includes training dentists worldwide, enabling us to gain a profound understanding of what General Practitioners require for success. Drawing on this wealth of knowledge, we've crafted an aligner product tailored specifically to meet the needs of your practice. At Progressive Orthodontics, the General Practitioner is our valued client, and our primary goal is to empower you to practice orthodontics at the highest industry standards while accommodating your practice volume. We're dedicated to ensuring that you deliver orthodontic care of the utmost quality.",
        },

        caseStudiesContent: {
          header: "Case Studies",
          paragraph:
            "After launching Progressive Aligners we’ll deliver insightful case studies where we dive into real-world success stories from patients using our aligner product. Each webinar offers a unique opportunity to explore practical applications of cutting-edge strategies, innovative solutions, and proven methodologies that drive results. Our case study webinars are a must attend to learn from our expert speakers and stay ahead of the curve.",
          linkText: "Click here to view",
        },
      },
      faq: {
        sectionTitle: "FAQ's",
        question1: "What if my Aligner order is defective upon arrival?",
        answer1:
          "Upon reaching out to our customer support team, we will ship you a replacement at no cost.",
        question2: "What if I cancel my Aligner order after it has shipped?",
        answer2:
          "If you cancel your order after it has shipped from our manufacturing facility, you will incur a $300 cancellation fee.",
        question3: "What if I don't have an intra-oral scanner?",
        answer3:
          'No problem! We recommend you create an account with OrthoSelect. They will convert your PVS impressions into a cleaned up, "full finish" STL file - idealized for making the best fitting aligners without an intra-oral scanner. Send OrthoSelect your PVS impressions along with a bite registration and intra-oral photos. You will receive the professionally detailed STL file in your OrthoSelect dashboard ready to download.',
        question4:
          "What if my Progressive Aligner order is delayed, not completed, or defective upon arrival?",
        answer4:
          "Upon reaching out to our customer support team, we will ship you a replacement at no cost.",
        question5: "Can I cancel my Progressive Aligner order before it ships?",
        answer5:
          "Approved Cases, still in manufacturing can be canceled before shipping at no cost.",
        question6: "What if I need to order replacement aligners?",
        answer6:
          "Replacement aligners cost $10 per aligner, plus a flat shipping and handling fee.",
        question7:
          "Should the patient change the aligners every week or every 2 weeks?",
        answer7part1:
          "The recommended wear time is 1-2 weeks because there is variability from one patient to another - the most significant of which is compliance in wearing the aligners. In addition to patient compliance, other factors that affect wear time are:",
        answer7part2:
          " Age of the patient - younger patients' teeth move more easily.",
        answer7part3:
          "Muscle strength - strong muscle patients have dense bone, so teeth move more slowly.",
        answer7part4:
          "Clinical crown size - smaller clinical crowns do not respond as well due to less surface area in contact with aligners.",
        answer7part5:
          "Complexity of movements involved in treatment, and how the movements were staged.",
        answer7part6:
          "If the patient is wearing the aligner diligently 22 hours per day, the 1-week wear schedule is ideal with our staging and movement protocols. We recommend starting with a 2-week wear schedule and reward the patient if the teeth are tracking well after the first couple of months by moving to 10 days and then 7 days if the teeth continue to track well. The most predictable wear schedule is to ask the patient not to change to the next aligner until all of the teeth fit perfectly into the current aligner and the aligner no longer feels tight.",
        question8: "How to submit a case?",
        answer8:
          "Please watch this video that provides the steps on how to submit a case.",
      },
      testimonialsSection: {
        title: "WHAT OUR CLIENTS SAY",
        subTitle: "TESTIMONIALS",
        testimonial1: {
          text: "Progressive Aligners has exceeded my expectations in terms of satisfaction and treatment outcomes. With its clear Aligners, patients experience minimal disruptions to their daily lives while achieving their desired smile.",
          name: "Jennifer Adams",
        },
        testimonial2: {
          text: "Having tried various orthodontic products, I can confidently say that Progressive Aligners stands out from the rest. Its advanced technology and customized treatment plans provide predictable and efficient tooth movement.",
          name: "Judi Roberts",
        },
        testimonial3: {
          text: "The ease of use, comfort, and effectiveness of Progressive Aligners make it an excellent choice for straightening teeth. I highly recommend it.",
          name: "Peter Stone",
        },
      },
      cta: {
        title: "Take the first step towards achieving your ideal smile.",
        subTitle:
          "Contact your doctor today to schedule a consultation and explore the best treatment options for you. Don't wait any longer to begin your journey towards a healthy and confident smile.",
        link: "Get Started",
      },
    },
    contact: {
      mainHeroHeader: "How can we help?",
      mainHeroSubHeader: "We look forward to helping you!",
      getInTouchHeader: "Get in Touch",
      getInTouchInfo:
        "For more information on our programs or pricing, call or submit an online inquiry.",
      emailHeader: "Email",
      emailInfo: "support@progressivealigners.com",
      phoneHeader: "Phone",
      phoneInfo: "(714) 973-2266",
      addressHeader: "Address",
      addressInfo: "135 Columbia, Suite #101 \n" + "Aliso Viejo, CA 92656",
      contactFormInfo:
        "Please provide us with the details of your inquiry, and we'll get back to you as soon as possible.",
      inputFirstName: "First Name *",
      inputLastName: "Last Name *",
      inputEmail: "Your Email *",
      inputPhone: "Your Phone Number *",
      inputMessage: "Your Message *",
      consentText:
        "I agree to receive information about my treatment from Progressive Aligners Inc. by email which may contain special offers, information on local dentist, and request for feedback about my experience. I also consent to Progressive Aligners contacting me via phone or text solely for the purpose of assisting me in finding a Progressive Aligners dentist and scheduling an appointment/consultation. Your personal data will be processed in accordance with our privacy statement.",
      sendButton: "Send Message",
    },
    message: {
      hello: "Hello World!",
    },
    footerSection: {
      corporate: {
        title: "Corporate",
        home: "Home",
        aboutUs: "About Us",
        patient: "Patient",
        doctor: "Doctor",
        faq: "FAQ's",
      },
      service: {
        title: "Service",
        contactUs: "Contact Us",
        findDoctors: "Find Doctors",
        doctorPortal: "Doctor Portal",
        privacyPolicy: "Privacy Policy",
      },
      getInTouch: {
        title: "Get in Touch",
        description:
          "We believe that a confident smile can transform your life. Our cutting-edge aligner technology and experienced orthodontic team are here to guide you on your journey towards a beautiful, straight smile.",
      },
      translation: {
        title: "Translation",
        teamSolutions: "Team Solutions",
      },
      copyright: "© 2024 Progressive Aligner Inc. All Rights Reserved",
      website: "www.posortho.com",
    },
  },
  es: {
    nav: {
      home: "Inicio",
      aboutUs: "Sobre Nosotros",
      patients: "Pacientes",
      doctor: "Doctor",
      contact: "Contacto",
      doctorPortal: "Portal del Doctor",
    },
    home: {
      header: "Iluminando los días con cada sonrisa",
      subheader: "Consiguiendo felicidad con cada sonrisa",
      aligners: {
        title: "Redefine tu confianza.",
        subtitle: "El camino hacia la sonrisa de tus sueños.",
        feature1Title: "EXTRAIBLES",
        feature1Text:
          "A diferencia de los brackets tradicionales, los alineadores son removibles. Eso ayuda a que puedas continuar disfrutando de tus comidas favoritas sin restricciones y manteniendo la rutina de higiene habitual.",
        feature2Title: "COMFORT",
        feature2Text:
          "Nuestros alineadores se diseñan de forma individualizada con un proceso cuidado y laborioso. Esto se traduce en bordes suaves y un buen asentamiento 360º que no produce irritación alguna.",
        feature3Title: "INVISIBLES",
        feature3Text:
          "Nuestros alineadores están fabricados con un material transparente libre de BPA que es prácticamente imperceptible a la vista. Esto te permite seguir con tu vida normal con absoluta confianza en cualquier entorno.",
        feature4Title: "EFICACES",
        feature4Text:
          "Nuestros alineadores se fabrican utilizando una avanzada tecnología 3D que permite una visión más precisa de los movimientos dentales y, por tanto, resulta en una experiencia de tratamiento más eficaz y predecible.",
      },
      alignersProcess: {
        consultationDescription:
          "Programa una consulta con un profesional dental acreditado para una primera revisión completa. Esta consulta incluirá un escaneado digital de tus dientes, fotografías y radiografías.",
        consultationTitle: "CONSULTA INICIAL",

        treatmentPlanDescription:
          "Partiendo del escaneado digital, el doctor creará un plan de tratamiento individualizado para ti. Utilizando un software especializado, simulará los movimientos de tus dientes a lo largo del tratamiento y, de esa manera, se podrán fabricar los alineadores que necesites.",
        treatmentPlanTitle: "PLAN DE TRATAMIENTO",

        alignersDesignDescription:
          "Tu plan de tratamiento se envía a nuestro laboratorio donde se fabrican tus alineadores. Se utiliza una tecnología muy avanzada empleando un material plástico transparente, libre de BPA.",
        alignersDesignTitle: "DISEÑO DE LOS ALINEADORES",

        treatmentProcessDescription:
          "Cuando los alineadores estén listos, tu doctor te enviará un juego de ellos para que los utilices. Cada alineador debe utilizarse durante un tiempo determinado y aproximadamente 20-22 horas por día. Sólo hay que quitárselos para comer, lavarse los dientes o beber líquidos calientes.",
        treatmentProcessTitle: "PROCESO DEL TRATAMIENTO",

        progressCheckupsDescription:
          "Durante el tratamiento  tendrás que acudir a citas periódicas con tu doctor. Estas citas permitirán monitorizar tu progreso, hacer los ajustes necesarios y poder cambiar al siguiente juego de alineadores.",
        progressCheckupsTitle: "REVISIONES DE PROGRESO",

        newSmileDescription:
          "Una vez finalizado el tratamiento, tus dientes habrán alcanzado la posición deseada. Sin embargo, para mantener el resultado, se recomienda utilizar retenedores.",
        newSmileTitle: "TU NUEVA SONRISA",
      },
      smilesSection: {
        title: "¡SONRISAS RESPLANDECIENTES TODOS LOS DÍAS!",
        description:
          "Nosotros pensamos que obtener una bonita sonrisa tiene que ser accesible a todo el mundo.",
        feature1: {
          title: "RESULTADOS EXCELENTES",
          text: "Los clientes nos eligen por nuestros buenos y consistentes resultados con alineadores dentales.",
        },
        feature2: {
          title: "COMODIDAD Y CONVENIENCIA",
          text: "Disfruta de la libertad de llevar alineadores removibles sin restricciones de comida y con un ajuste muy cómodo.",
        },
        feature3: {
          title: "ATENCIÓN PERSONALIDAZA",
          text: "Entendemos que cada sonrisa es única y, por eso, brindamos un enfoque personalizado para cada tratamiento con alineadores.",
        },
      },
      consultationSection: {
        heading: "¿LISTO PARA UNA CONSULTA?",
        description:
          "Podemos ayudarte a encontrar un odontólogo especializado en Progressive Aligners cerca de ti. ",
        findDoctorButton: "Encontrar un doctor",
      },
      testimonialsSection: {
        title: "LO QUE DICEN NUESTROS CLIENTES",
        subTitle: "TESTIMONIOS",
        testimonial1: {
          text: "Los alineadores de Progressive han superado mis expectativas en cuanto a satisfacción y resultados del tratamiento. Los pacientes no notan prácticamente ninguna molestia en sus rutinas habituales mientras están consiguiendo la sonrisa que desean. ",
          name: "JENNIFER ADAMS",
        },
        testimonial2: {
          text: "He probado otros tipos de alineadores y puedo decir con confianza que Progressive Aligners sobresale del resto. Su avanzada tecnología y sus planes de tratamiento individualizados proporcionan movimientos dentales eficaces y predecibles.",
          name: "JUDI ROBERTS",
        },
        testimonial3: {
          text: "La facilidad de uso, la comodidad y la eficacia de Progressive Aligners hacen que sean una excelente elección para enderezar los dientes. Los recomiendo. ",
          name: "PETER STONE",
        },
      },
    },
    patient: {
      alignersInfoSection: {
        candidateQuestion: "¿Soy un candidato?",
        transformSmile: "¡Transforma tu sonrisa hoy!",
        firstStepTitle: "DA EL PRIMER PASO PARA CONSEGUIR TU SONRISA IDEAL.",
        firstStepDescription:
          "Contacta hoy mismo con tu doctor para agendar una consulta y conocer cuáles son las mejores opciones de tratamiento para tí. No esperes más para comenzar tu viaje hacia una sonrisa sana y atractiva.",
        rightForYouTitle:
          "¿SON LOS ALINEADORES DE PROGRESSIVE ADECUADOS PARA MI?",
        smileDescription: "¿Qué opción describe mejor mi sonrisa?",
        consultationTitle: "¿Listo para una consulta?",
        consultationDescription:
          "Podemos ayudarte a encontrar un doctor especializado en Progressive Aligners cerca de ti.",
        findDoctorButton: "ENCONTRAR UN DOCTOR",
        getStartedButton: "EMPEZAR",
      },
      alignersConditions: {
        crowding: {
          title: "Apiñamiento",
          description:
            "El apiñamiento es un problema muy común en ortodoncia y significa que no hay suficiente espacio para que tus dientes encajen correctamente. Tratar el problema es crucial para evitar un daño en dientes y encías a largo plazo.",
        },
        spacing: {
          title: "Espacios",
          description:
            "Al contrario que en el apiñamiento hay exceso de espacio para los dientes y estos pueden moverse libremente generando huecos inesperados entre ellos.",
        },
        crossbite: {
          title: "Mordida cruzada",
          description:
            "Una mordida cruzada es un alineamiento incorrecto de las arcadas que provoca un cierre de los dientes formando un ángulo. Si la mordida cruzada es posterior, los dientes traseros superiores muerden por dentro de los inferiores. Si es anterior, los dientes frontales superiores se posicionan por detrás de los inferiores. Esto puede afectar a uno o varios dientes.",
        },
        openBite: {
          title: "Mordida abierta",
          description:
            "Una mordida abierta se manifiesta normalmente cuando los dientes posteriores contactan al morder pero los anteriores no lo hacen. Normalmente hay un hueco excesivo entre los dientes superiores e inferiores delanteros.",
        },
        overbite: {
          title: "Sobremordida",
          description:
            "Una sobremordida ocurre cuando hay un alineamiento incorrecto de los dientes que resulta en dientes superiores protruidos que se superponen a los dientes inferiores.",
        },
        deepOverjet: {
          title: "Mordida profunda",
          description:
            "Es la condición en la que los dientes frontales se extienden en exceso con respecto a los dientes frontales inferiores. Suele haber un hueco horizontal visible o espacio entre dientes superiores e inferiores cuando las mandíbulas se cierran.",
        },
      },
      faqSection: {
        title: "PREGUNTAS FRECUENTES",
        introduction:
          "Puedes explorar esta sección para encontrar las respuestas a las preguntas más habituales. O también puedes contactar con nosotros para poder ayudarte.",
        questions: {
          question1: {
            title: "¿EN QUÉ CONSISTE EL TRATAMIENTO CON PROGRESSIVE ALIGNERS?",
            answer:
              "En utilizar una serie de alineadores transparentes hechos de un material plástico libre de BPA. De esa forma tus dientes se irán alineando de forma gradual. No serán necesarios brackets ni arcos tradicionales y no habrá las restricciones asociadas a esos productos.",
          },
          question2: {
            title:
              "¿PARA QUIÉN ESTÁN INDICADOS LOS ALINEADORES DE PROGRESSIVE?",
            answer:
              "Nuestros alineadores son una opción excelente para niños, adolescentes o adultos que quieran mejorar su sonrisa.",
          },
          question3: {
            title:
              "¿DE QUÉ ESTÁN HECHOS LOS ALINEADORES DE PROGRESSIVE Y QUÉ ASPECTO TIENEN?",
            answer:
              "Nuestros alineadores están fabricados de un material elástico, libre de BPA y con memoria. Junto con una ligera difusión multi capa y una superficie antireflectante. Eso logra que sean prácticamente indetectables.",
          },
          question4: {
            title: "¿CÓMO CONSIGUE PROGRESSIVE ALIGNERS ENDEREZAR MIS DIENTES?",
            answer:
              "Tu doctor diseñará un plan digital individualizado a tus necesidades que señalará todos los movimientos que necesite cada diente. Tus alineadores se fabricarán para aplicar la fuerza precisa en los lugares donde sea necesario. Durante el tratamiento tu doctor revisará el progreso para asegurarse de que los alineadores ajustan correctamente y darte el soporte necesario en cada etapa.",
          },
          question5: {
            title:
              "¿CUÁLES SON LOS BENEFICIOS PRNCIPALES DEL TRATAMIENTO CON PROGRESSIVE ALIGNERS?",
            answer:
              "Nuestros alineadores ofrecen una opción de tratamiento prácticamente invisible. Es muy difícil que otras personas perciban que los llevas puestos. El material del que están fabricados es muy cómodo y flexible y así consiguen que ponerlos y retirarlos sea un procedimiento sencillo.",
          },
          question6: {
            title:
              "¿HAY GARANTÍA DE QUE CON PROGRESSIVE ALIGNERS SE ENDERECEN MIS DIENTES?",
            answer:
              "Cada sonrisa es única y por eso tu plan de tratamiento con alineadores comienza con una consulta realizada por uno de nuestros doctores de Progressive Aligners. Durante esa consulta el doctor te ayudará a determinar qué tratamiento es el mejor para conseguir la sonrisa ideal. No podemos garantizar cada resultado individual pero sí podemos decir que la mayoría de los pacientes han alcanzado los objetivos que pretendían.",
          },
          question7: {
            title: "¿CUÁL ES EL PROCESO DEL TRATAMIENTO?",
            answer:
              "El primer paso es agendar una cita con un doctor de Progressive Aligners. Durante la consulta el doctor te realizará un examen oral completo incluyendo un escaneado digital. Después el doctor creará un plan de tratamiento adaptado a tus necesidades y comenzará a diseñar los alineadores. Cuando estos estén fabricados, tu doctor te entregará una serie de alineadores y te indicará durante cuánto tiempo utilizarlos. Normalmente hay que usarlos de 20 a 22 horas al día quitándolos sólo para cepillar los dientes, comer o beber líquidos calientes. Durante el tratamiento habrá visitas periódicas al doctor para revisiones.",
          },
          question8: {
            title: "¿CUÁNTO TIEMPO DURA EL TRATAMIENTO?",
            answer:
              "La duración del tratamiento varía en función de la complejidad del caso. Puede completarse en sólo unos seis meses. Podemos asegurarte que los resultados serán visibles enseguida y podrás así comprobar la eficacia del tratamiento.",
          },
          question9: {
            title: "¿CUÁL ES LA MEJOR MANERA DE LIMPIAR MIS ALINEADORES?",
            answer:
              "La mejor manera de limpiar los alineadores es cepillarlos con enjuage bucal o utilizar algún limpiador para alineadores y aclararlos con agua tibia antes de colocarlos de nuevo en la boca.",
          },
          question10: {
            title: "¿QUÉ DEBO HACER SI PIERDO O ROMPO MI ALINEADOR?",
            answer:
              "Tienes que contactar inmediatamente con tu doctor para que pueda pedirte otro juego de alineadores. Esto ayudará a que no pierdas el ajuste y puedas seguir consiguiendo esa sonrisa que deseas.",
          },
          question11: {
            title:
              "¿PUEDO PEDIR MIS ALINEADORES DIRECTAMENTE A PROGRESSIVE ALIGNERS?",
            answer:
              "Desafortunadamente eso no es posible. Todos los alineadores tienen que ser solicitados por un profesional autorizado.",
          },
        },
      },
      testimonialsSection: {
        title: "LO QUE DICEN NUESTROS CLIENTES",
        subTitle: "TESTIMONIOS",
        testimonial1: {
          text: "Los alineadores de Progressive han superado mis expectativas en cuanto a satisfacción y resultados del tratamiento. Los pacientes no notan prácticamente ninguna molestia en sus rutinas habituales mientras están consiguiendo la sonrisa que desean. ",
          name: "JENNIFER ADAMS",
        },
        testimonial2: {
          text: "He probado otros tipos de alineadores y puedo decir con confianza que Progressive Aligners sobresale del resto. Su avanzada tecnología y sus planes de tratamiento individualizados proporcionan movimientos dentales eficaces y predecibles.",
          name: "JUDI ROBERTS",
        },
        testimonial3: {
          text: "La facilidad de uso, la comodidad y la eficacia de Progressive Aligners hacen que sean una excelente elección para enderezar los dientes. Los recomiendo. ",
          name: "PETER STONE",
        },
      },
    },
    about: {
      header: "SOBRE NOSOTROS",
      subHeader: "Priorizamos la satisfacción del cliente por encima de todo.",
      section1Header: "LA ORTODONCIA ES NUESTRA PASION",
      section1Paragraph:
        "En Progressive Orthodontics nos dedicamos a ofrecer un sistema de ortodoncia fácilmente reproducible que permite a los dentistas conseguir unas sonrisas sanas y atractivas para sus pacientes. Desde nuestra fundación en 1984, nos hemos mantenido constantemente a la vanguardia de la tecnología para asegurar un servicio excepcional a nuestros clientes, a quienes orgullosamente consideramos parte de la familia POS. La demanda ha evolucionado mucho en este campo y por eso hemos invertido años en investigación y desarrollo para adaptarnos a los nuevos tiempos. Nos complace presentaros ahora nuestra propia marca de alineadores: Progressive Aligners. Esta aventura lleva mucho tiempo preparándose y estamos entusiamados de poder presentaros nuestra nueva manera de iluminar sonrisas a diario.",
      section2Header: "SUPEREMOS EL ESTANDAR DE CUIDADO",
      section2Paragraph1:
        "Nos apasiona la odontología y siempre hemos pensado que las cosas, en este ámbito, se pueden hacer mejor. Muchos profesionales consideran los alineadores únicamente un accesorio cosmético. Sólo hay que leer acerca de la tendencia de ortodoncia “hágalo usted mismo”. Nuestra pasión por la profesión hace que creamos firmemente en el potencial de mejora de nuestra comunidad. Desafortunadamente en este campo hay muchos que no reconocen los alineadores como algo más que un procedimiento cosmético. Os animamos a que leais más sobre “´hágalo usted mismo”.",
      section2Paragraph2:
        "La terapia con alineadores es una conocida rama de la ortodoncia que demanda la máxima seriedad y consideración por el bienestar de los pacientes. El alineador es un instrumento de ortodoncia muy eficaz que tiene la habilidad de corregir maloclusiones, alinear dientes y mejorar la salud oral a largo plazo. Es crucial que la utilización de alineadores no se limite al diagnóstico de un técnico de laboratorio sino que requiera la intervención de un dentista bien formado que pueda asegurar un plan de tratamiento apropiado y una ejecución y monitorización adecuadas.",
      section2Paragraph3:
        "Tenemos la firme convicción de que cualquier profesional que ofrezca tratamientos con alineadores debe incluir los registros necesarios, un diagnóstico de la mordida del paciente, documentación de diversas opciones de tratamiento y un plan de tratamiento bien definido. \n" +
        "Nuestros programas de formación habilitan a los dentistas para ofrecer el estandar de cuidado establecido por el American Board of Orthodontics (ABO) de una manera práctica y eficaz. Clic aquí para más información sobre ",
      section2Paragraph3AndOur: "y nuestro",
      section2Link1Text: "Tendencia de Ortodoncia DIY",
      section2Link2Text:
        "Haga clic aquí para obtener más información sobre nuestro programa Aligner +",
      section2Link3Text: "Programa Esencial de Alineadores",
      section3Header:
        "PROGRESSIVE ALIGNERS REVOLUCIONARÁ LA MANERA DE ENTENDER LOS ALINEADORES",
      section3Paragraph:
        "Motivada por el sentido de responsabilidad de compartir nuestra experiencia, POS siempre ha tenido una evolución progresiva. Después de años de innovación y cambios y gracias a la dedicación de graduados que han proporcionado a sus clientes el mejor cuidado, hemos podido cambiar el campo de la odontología, y más en concreto, de la ortodoncia. Como resultado de ello, dentistas de todo el mundo tienen ahora la oportunidad de ofrecer en sus consultas el sistema de alineadores más innovador. Esa es la manera en la que sus pacientes podrán conseguir la sonrisa que desean y seguir siendo fuente de inspiración para otros.",
    },
    doctor: {
      header: "EL ALINEADOR DISEÑADO PARA EL DENTISTA GENERALISTA",
      subHeader: "Ofrezca a sus pacientes la sonrisa que siempre han soñado.",
      paragraph1:
        "Progressive Orthodontics siempre ha estado dirigida a la formación de dentistas generales y hemos utilizado nuestra extensa experiencia para crear una solución de alineadores a medida para ellos que pueda cumplir perfectamente con sus necesidades. En colaboración con un fabricante líder en el sector, hemos adaptado su producto para que se pueda integrar fácilmente y sin problemas en el entorno de la consulta de un dentista general. Hemos desarrollado un programa educativo para poder proporcionarte los conocimientos más actualizados. De manera adicional, hemos introducido un sistema de ayuda innovador que llamamos “Coaching” y que te garantiza tener un mentor como guía para conseguir el éxito en cada caso. Nuestros planes de precios están meticulosamente pensados para adaptarse al tipo de consulta con el alto volumen de trabajo de un generalista, entendiendo que los alineadores son sólo una de las facetas de vuestras responsabilidades profesionales. Por encima de todo nuestro principal objetivo sigues siendo tú, el dentista general y hemos diseñado cuidadosamente cada aspecto de nuestro producto para cubrir tus necesidades específicas.",

      ceoHeader: "CEO MILES MCGANN",
      ceoParagraph:
        "La presentación de nuestros alineadores junto con el excelente programa de coaching marca una nueva era en el cuidado ortodóncico. Juntos queremos construir un futuro para el alineamiento de los dientes en el que cualquiera pueda sonreír con confianza y con una sonrisa bonita.",

      drHeader: "DR. COLLINS HARRELL",
      drParagraph:
        "El lanzamiento de Progressive Aligners representa un avance significativo en el campo de la ortodoncia, ofreciendo una solución innovadora, cómoda y eficaz para conseguir bonitas sonrisas. Estamos muy emocionados de embarcarnos en este viaje, transformando sonrisas y mejorando la autoconfianza de los pacientes por todo el mundo.",
      progressiveAdvantage: {
        sectionTitle: "LA VENTAJA DE PROGRESSIVE",

        tabCoaching: "COACHING Y SOPORTE",
        tabEducation: "EDUCACIÓN",
        tabTieredPricing: "PLAN DE PRECIOS",
        tabSuperiorProduct: "PRODUCTO SUPERIOR",
        tabCompanyExperience: "EXPERIENCIA DE LA EMPRESA",
        tabCaseStudies: "ESTUDIOS DE CASO",

        coachingContent: {
          header: "COACHING Y SOPORTE",
          paragraph:
            "El equipo clínico de Progressive Aligners se dedica a ofrecerte un soporte exhaustivo en cada caso adaptándose a las necesidades específicas de cada uno. Hemos diseñado tres niveles diferentes de soporte para garantizar eso. Cada usuario de Progressive Aligners tiene la opción de seleccionar un profesional que le ofrezca su ayuda. Esta opción es completamente gratuita.  El “coach” elegido ayuda a determinar si un caso determinado es adecuado para cada doctor, en función de su formación y asistirá con las cuestiones básicas. Si se requiere más ayuda para tratar el caso hasta su término, nuestro servicio de Mentoring asegura tener siempre a mano la guía experta de un profesional (este servicio es de pago).",
        },

        educationContent: {
          header: "EDUCACIÓN",
          paragraph:
            "Reconocemos los retos a los que se enfrentan las consultas de los dentistas generales para mantenerse actualizados en una industria que está cambiando tanto como la de los alineadores. Por esta razón hemos diseñado diferentes cursos disponibles en varios formatos que permiten adquirir las habilidades y los conocimientos necesarios para utilizar nuestros alienadores de una forma práctica y eficaz. Estos cursos se acomodan a las necesidades específicas de un dentista general ofreciendo flexibilidad y conveniencia. Adicionalmente hemos preparado una serie de casos complementarios que estarán disponibles en la website para proporcionar un aprendizaje continuo en el momento adecuado para cada uno.",
        },

        tieredPricingContent: {
          header: "PLAN DE PRECIOS",
          paragraph:
            "La ortodoncia representa sólo una parte de vuestras consultas y hemos diseñado un plan de precios y un programa de descuentos para adaptarse a ello lo máximo posible. Nuestro objetivo es que podáis utilizar un producto excepcional a precios realmente competitivos con otras marcas de renombre en el sector. \n" +
            "Nuestro programa de precios se basa en vuestro historial de casos en los 6 meses previos. Si nos facilitáis evidencia de vuestros casos de alineadores con otra compañía comenzados en los 6 últimos meses podréis optar al descuento correspondiente en Progressive Aligners.\n",
        },

        superiorProductContent: {
          header: "PRODUCTO SUPERIOR",
          paragraph:
            "Nos enorgullecemos de garantizar que nuestro producto supere los estándares de la industria, estableciendo un nuevo punto de referencia de excelencia. Además de reunir a los mejores diseñadores de la industria, hemos incorporado técnicas de diseño de vanguardia que brindan funcionalidad y estética inigualables para cualquier paciente. Además, nuestra interfaz fácil de usar garantiza una comunicación fluida con nuestro equipo de diseño, lo que permite una colaboración eficiente y sin esfuerzo en cada paso de su caso.",
        },

        companyExperienceContent: {
          header: "EXPERIENCIA DE LA EMPRESA",
          paragraph:
            "Nos enorgullecemos de garantizar que nuestro producto supere los estándares de la industria, estableciendo un nuevo punto de referencia de excelencia. Además de reunir a los mejores diseñadores de la industria, hemos incorporado técnicas de diseño de vanguardia que brindan funcionalidad y estética inigualables para cualquier paciente. Además, nuestra interfaz fácil de usar garantiza una comunicación fluida con nuestro equipo de diseño, lo que permite una colaboración eficiente y sin esfuerzo en cada paso de su caso.",
        },

        caseStudiesContent: {
          header: "Estudios de caso",
          paragraph:
            "Después del lanzamiento de Progressive Aligners, ofreceremos estudios de casos interesantes en los que profundizaremos en historias de éxito del mundo real de pacientes que utilizan nuestro producto alineador. Cada seminario web ofrece una oportunidad única para explorar aplicaciones prácticas de estrategias de vanguardia, soluciones innovadoras y metodologías comprobadas que impulsan resultados. Es imprescindible asistir a nuestros seminarios web de estudios de casos para aprender de nuestros oradores expertos y mantenerse a la vanguardia.",
          linkText: "Haga clic aquí para ver",
        },
      },
      faq: {
        sectionTitle: "PREGUNTAS FRECUENTES",
        question1:
          "¿QUE PASA SI MIS ALINEADORES HAN LLEGADO Y HAY ALGUN PROBLEMA?",
        answer1:
          "Si el producto es defectuoso será reemplazado sin coste alguno.",
        question2:
          "¿QUE PASA SI CANCELO MI PEDIDO DE ALINEADORES DESPUÉS DE QUE SE HAYA ENVIADO?",
        answer2:
          "Si la cancelación se produce una vez que el pedido ha sido enviado, habrá una penalización de 300 Euros.",
        question3: "¿QUE PASA SI NO TENGO UN ESCÁNER INTRAORAL?",
        answer3:
          "No hay ningún problema. Recomendamos utilizar un laboratorio como Orthoselect que convierta las impresiones PVS en un archivo STL. Lo ideal, por supuesto, es disponer de un escáner intraoral. Para el laboratorio se necesita enviar las impresiones junto con un registro de mordida y fotos intraorales.",
        question4:
          "¿QUE PASA SI MI PEDIDO SE RETRASA O ENCUENTRO ALGÚN DEFECTO CUANDO LO RECIBO?",
        answer4:
          "Recibirá un reemplazo sin coste una vez haya contactado con el servicio de ayuda.",
        question5: "¿PUEDO CANCELAR MI PEDIDO ANTES DE QUE SEA ENVIADO?",
        answer5:
          "Los casos aprobados pueden cancelarse sin coste mientras estén en proceso de fabricación.",
        question6: "¿QUÉ OCURRE SI NECESITO PEDIR REEMPLAZO DE ALINEADORES?",
        answer6:
          "Hay un coste de 25 Euros por alineador además de los gastos de envío.",
        question7:
          "¿CUÁNDO DEBE EL PACIENTE CAMBIARSE EL ALINEADOR, CADA SEMANA O CADA DOS SEMANAS?",
        answer7part1:
          "La recomendación es utilizarlo 1-2 semanas pero esto varía de un paciente a otro sobre todo en lo que se refiere a cooperación y tiempo de uso. Aparte de la cooperación hay otros factores a considerar:",
        answer7part2:
          "Edad del paciente: cuanto más joven más fácil se mueven los dientes.",
        answer7part3:
          "Fuerza muscular: los pacientes con fuerte musculatura tienen hueso más denso y los dientes se mueven más lentamente",
        answer7part4:
          "Altura clínica de la corona: las más pequeñas no responden tan bien ya que hay menos superficie en contacto con el alineador.",
        answer7part5:
          "La complejidad de movimientos implicados en el tratamiento y cómo se han planificado.",
        answer7part6:
          "Si el paciente utiliza los alineadores las 22 horas por día recomendadas, el cambio cada semana sería ideal. Se recomienda comenzar con cambios cada 2 semanas y recompensar al paciente cambiando a 1 semana si los dientes se están moviendo correctamente después de un par de meses. Lo mejor es pedirle al paciente que no cambie al siguiente alineador hasta que el ajuste del alineador previo sea perfecto y ya no lo sienta prieto.",
        question8: "¿Cómo enviar un caso?",
        answer8:
          "Por favor, mira este video que proporciona los pasos sobre cómo enviar un caso.",
      },
      testimonialsSection: {
        title: "LO QUE DICEN NUESTROS CLIENTES",
        subTitle: "TESTIMONIOS",
        testimonial1: {
          text: "Los alineadores de Progressive han superado mis expectativas en cuanto a satisfacción y resultados del tratamiento. Los pacientes no notan prácticamente ninguna molestia en sus rutinas habituales mientras están consiguiendo la sonrisa que desean. ",
          name: "JENNIFER ADAMS",
        },
        testimonial2: {
          text: "He probado otros tipos de alineadores y puedo decir con confianza que Progressive Aligners sobresale del resto. Su avanzada tecnología y sus planes de tratamiento individualizados proporcionan movimientos dentales eficaces y predecibles.",
          name: "JUDI ROBERTS",
        },
        testimonial3: {
          text: "La facilidad de uso, la comodidad y la eficacia de Progressive Aligners hacen que sean una excelente elección para enderezar los dientes. Los recomiendo. ",
          name: "PETER STONE",
        },
      },
      cta: {
        title: "DA EL PRIMER PASO PARA CONSEGUIR TU SONRISA IDEAL",
        subTitle:
          "Contacta hoy mismo con tu doctor para agendar una consulta y conocer cuáles son las mejores opciones de tratamiento para ti. No esperes más para comenzar tu viaje hacia una sonrisa sana y atractiva.",
        link: "Empezar",
      },
    },
    contact: {
      mainHeroHeader: "¿Cómo podemos ayudarte?",
      mainHeroSubHeader: "¡Esperamos poder ayudarte!",
      getInTouchHeader: "Ponerse en Contacto",
      getInTouchInfo:
        "Para obtener más información sobre nuestros programas o precios, llama o envía una consulta en línea.",
      emailHeader: "Correo Electrónico",
      emailInfo: "support@progressivealigners.com",
      phoneHeader: "Teléfono",
      phoneInfo: "(714) 973-2266",
      addressHeader: "Dirección",
      addressInfo: "135 Columbia, Suite #101\n" + "Aliso Viejo, CA 92656",
      contactFormInfo:
        "Por favor, proporciona los detalles de tu consulta y te responderemos lo antes posible.",
      inputFirstName: "Nombre *",
      inputLastName: "Apellido *",
      inputEmail: "Tu Correo Electrónico *",
      inputPhone: "Tu Número de Teléfono *",
      inputMessage: "Tu Mensaje *",
      consentText:
        "Acepto recibir información sobre mi tratamiento de Progressive Aligners Inc. por correo electrónico, que puede contener ofertas especiales, información sobre dentistas locales y solicitudes de comentarios sobre mi experiencia. También doy mi consentimiento para que Progressive Aligners se comunique conmigo por teléfono o mensaje de texto exclusivamente para ayudarme a encontrar un dentista de Progressive Aligners y programar una cita/consulta. Tus datos personales serán procesados de acuerdo con nuestra declaración de privacidad.",
      sendButton: "Enviar Mensaje",
    },
    message: {
      hello: "Hola Mundo!",
    },
    footerSection: {
      corporate: {
        title: "EMPRESA",
        home: "Inicio",
        aboutUs: "Sobre nosotros",
        patient: "Paciente",
        doctor: "Doctor",
        faq: "Preguntas frecuentes",
      },
      service: {
        title: "SERVICIOS",
        contactUs: "Contacto",
        findDoctors: "Encontrar un doctor",
        doctorPortal: "Portal del doctor",
        privacyPolicy: "Política de privacidad",
      },
      getInTouch: {
        title: "SIGUENOS",
        description:
          "Creemos que confiar en tu sonrisa puede transformar tu vida. Nuestra tecnología en alineadores de útlima generación y nuestro experto equipo en ortodoncia están aquí para guiarte en el viaje hacia una maravillosa sonrisa.",
      },
      translation: {
        title: "IDIOMAS",
        teamSolutions: "SOLUCIONES DE EQUIPO",
      },
      copyright: "© 2024 Progressive Aligner Inc. All Rights Reserved",
      website: "www.posortho.com",
    },
  },
};

export default messages;
