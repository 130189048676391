<template>
  <section class="main-hero mb-5 offblue">
    <div class="container">
      <div class="row">
        <div class="align-items-center col-md-6 d-flex">
          <div>
            <h1>{{ $t("home.header") }}</h1>
            <h5 class="text-primary">
              {{ $t("home.subheader") }}
            </h5>
          </div>
        </div>
        <div class="col-md-6">
          <img class="img-fluid" src="assets/img/doctor-hero.png" />
        </div>
      </div>
    </div>
  </section>
  <section class="mb-5 mt-5">
    <div class="container">
      <div class="row">
        <div class="align-items-center col-md-5 d-flex">
          <img src="assets/img/try.png" class="img-fluid" />
        </div>
        <div class="col-md-7">
          <h2>{{ $t("home.aligners.title") }}</h2>
          <h5 class="text-muted">{{ $t("home.aligners.subtitle") }}</h5>
          <div class="mt-5 row">
            <div class="col-md-6">
              <h4 class="text-primary">
                {{ $t("home.aligners.feature1Title") }}
              </h4>
              <p>{{ $t("home.aligners.feature1Text") }}</p>
            </div>
            <div class="col-md-6">
              <h4 class="text-primary">
                {{ $t("home.aligners.feature2Title") }}
              </h4>
              <p>
                {{ $t("home.aligners.feature2Text") }}
              </p>
            </div>
          </div>
          <div class="mt-3 row">
            <div class="col-md-6">
              <h4 class="text-primary">
                {{ $t("home.aligners.feature3Title") }}
              </h4>
              <p>
                {{ $t("home.aligners.feature3Text") }}
              </p>
            </div>
            <div class="col-md-6">
              <h4 class="text-primary">
                {{ $t("home.aligners.feature4Title") }}
              </h4>
              <p>
                {{ $t("home.aligners.feature4Text") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="offblue">
    <div class="container">
      <div class="row">
        <div class="col-md-4">
          <div class="small-7 medium-5 large-4 column end small-centered card">
            <div class="card-top">
              <a href="#0"> <div class="overlay"></div></a>
              <img class="card-img" src="assets/img/treatment-icon.png" />
            </div>
            <div class="triangle">
              <div class="circle-button" id="circleDrop01">
                <img
                  class="close circleDrop01"
                  src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/324479/close.svg"
                />
              </div>
            </div>
            <div class="card-middle circleDrop01">
              <p class="more">
                {{ $t("home.alignersProcess.consultationDescription") }}
              </p>
            </div>
            <div class="card-bottom text-center">
              <h3 class="card-title">
                {{ $t("home.alignersProcess.consultationTitle") }}
              </h3>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="small-7 medium-5 large-4 column end small-centered card">
            <div class="card-top">
              <a href="#0"> <div class="overlay"></div></a>
              <img class="card-img" src="assets/img/consultation-icon.png" />
            </div>
            <div class="triangle">
              <div class="circle-button" id="circleDrop02">
                <img
                  class="close circleDrop02"
                  src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/324479/close.svg"
                />
              </div>
            </div>
            <div class="card-middle circleDrop02">
              <p class="more">
                {{ $t("home.alignersProcess.treatmentPlanDescription") }}
              </p>
            </div>
            <div class="card-bottom text-center">
              <h3 class="card-title">
                {{ $t("home.alignersProcess.treatmentPlanTitle") }}
              </h3>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="small-7 medium-5 large-4 column end small-centered card">
            <div class="card-top">
              <a href="#0"> <div class="overlay"></div></a>
              <img class="card-img" src="assets/img/design-icon1.png" />
            </div>
            <div class="triangle">
              <div class="circle-button" id="circleDrop03">
                <img
                  class="close circleDrop03"
                  src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/324479/close.svg"
                />
              </div>
            </div>
            <div class="card-middle circleDrop03">
              <p class="more">
                {{ $t("home.alignersProcess.alignersDesignDescription") }}
              </p>
            </div>
            <div class="card-bottom text-center">
              <h3 class="card-title">
                {{ $t("home.alignersProcess.alignersDesignTitle") }}
              </h3>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="small-7 medium-5 large-4 column end small-centered card">
            <div class="card-top">
              <a href="#0"> <div class="overlay"></div></a>
              <img class="card-img" src="assets/img/process-icon.png" />
            </div>
            <div class="triangle">
              <div class="circle-button" id="circleDrop04">
                <img
                  class="close circleDrop04"
                  src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/324479/close.svg"
                />
              </div>
            </div>
            <div class="card-middle circleDrop04">
              <p class="more">
                {{ $t("home.alignersProcess.treatmentProcessDescription") }}
              </p>
            </div>
            <div class="card-bottom text-center">
              <h3 class="card-title">
                {{ $t("home.alignersProcess.treatmentProcessTitle") }}
              </h3>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="small-7 medium-5 large-4 column end small-centered card">
            <div class="card-top">
              <a href="#0"> <div class="overlay"></div></a>
              <img class="card-img" src="assets/img/healing-icon.png" />
            </div>
            <div class="triangle">
              <div class="circle-button" id="circleDrop05">
                <img
                  class="close circleDrop05"
                  src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/324479/close.svg"
                />
              </div>
            </div>
            <div class="card-middle circleDrop05">
              <p class="more">
                {{ $t("home.alignersProcess.progressCheckupsDescription") }}
              </p>
            </div>
            <div class="card-bottom text-center">
              <h3 class="card-title">
                {{ $t("home.alignersProcess.progressCheckupsTitle") }}
              </h3>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="small-7 medium-5 large-4 column end small-centered card">
            <div class="card-top">
              <a href="#0"> <div class="overlay"></div></a>
              <img class="card-img" src="assets/img/face-icon.png" />
            </div>
            <div class="triangle">
              <div class="circle-button" id="circleDrop06">
                <img
                  class="close circleDrop06"
                  src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/324479/close.svg"
                />
              </div>
            </div>
            <div class="card-middle circleDrop06">
              <p class="more">
                {{ $t("home.alignersProcess.newSmileDescription") }}
              </p>
            </div>
            <div class="card-bottom text-center">
              <h3 class="card-title">
                {{ $t("home.alignersProcess.newSmileTitle") }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="mb-5 mt-5">
    <div class="container">
      <div class="align-items-center row">
        <div class="col-md-6">
          <img src="assets/img/family-smile.png" class="img-fluid p-4" />
        </div>
        <div class="align-items-center col-md-6">
          <h2>{{ $t("home.smilesSection.title") }}</h2>
          <p>
            {{ $t("home.smilesSection.description") }}
          </p>
          <div class="wrapper">
            <div class="mb-3 mt-5 row">
              <div class="circle">
                <div class="wrap">
                  <span><i class="fa fa-2x fa-thumbs-o-up"></i></span>
                </div>
              </div>
              <div class="text">
                <h4 class="text-primary">
                  {{ $t("home.smilesSection.feature1.title") }}
                </h4>
                <p class="mb-1 pt-4">
                  {{ $t("home.smilesSection.feature1.text") }}
                </p>
              </div>
            </div>
            <div class="mb-3 row">
              <div class="circle">
                <div class="wrap">
                  <span><i class="fa fa-2x fa-smile-o"></i></span>
                </div>
              </div>
              <div class="text">
                <h4 class="mb-1 text-primary">
                  {{ $t("home.smilesSection.feature2.title") }}
                </h4>
                <p class="mb-1 pt-4">
                  {{ $t("home.smilesSection.feature2.text") }}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="circle">
                <div class="wrap">
                  <span><i class="fa fa-2x fa-user"></i></span>
                </div>
              </div>
              <div class="text">
                <h4 class="mb-1 text-primary">
                  {{ $t("home.smilesSection.feature3.title") }}
                </h4>
                <p class="mb-1 pt-4">
                  {{ $t("home.smilesSection.feature3.text") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="mb-5 mt-5">
    <div class="container">
      <div class="row">
        <div
          class="align-items-center border col-md-10 d-flex flex-wrap offset-md-1 p-4 round-corners"
        >
          <div class="col-md-8">
            <h2>{{ $t("home.consultationSection.heading") }}</h2>
            <p>{{ $t("home.consultationSection.description") }}</p>
          </div>
          <div class="col-md-4">
            <router-link to="find-doctor">
              <button class="btn btn-block btn-lg btn-primary float-right">
                {{ $t("home.consultationSection.findDoctorButton") }}
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="testimonial">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 d-none d-lg-block">
          <ol class="carousel-indicators tabs">
            <li
              data-target="#carouselExampleIndicators"
              data-slide-to="0"
              class="active"
            >
              <figure>
                <img
                  src="https://livedemo00.template-help.com/wt_62267_v8/prod-20823-one-service/images/testimonials-01-179x179.png"
                  class="img-fluid"
                  alt=""
                />
              </figure>
            </li>
            <li data-target="#carouselExampleIndicators" data-slide-to="1">
              <figure>
                <img
                  src="https://livedemo00.template-help.com/wt_62267_v8/prod-20823-one-service/images/testimonials-02-306x306.png"
                  class="img-fluid"
                  alt=""
                />
              </figure>
            </li>
            <li data-target="#carouselExampleIndicators" data-slide-to="2">
              <figure>
                <img
                  src="https://livedemo00.template-help.com/wt_62267_v8/prod-20823-one-service/images/testimonials-03-179x179.png"
                  class="img-fluid"
                  alt=""
                />
              </figure>
            </li>
          </ol>
        </div>
        <div class="col-lg-6 d-flex justify-content-center align-items-center">
          <div
            id="carouselExampleIndicators"
            data-interval="false"
            class="carousel slide"
            data-ride="carousel"
          >
            <h3 class="text-muted">
              {{ $t("home.testimonialsSection.title") }}
            </h3>
            <h1>{{ $t("home.testimonialsSection.subTitle") }}</h1>
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div class="quote-wrapper">
                  <p>
                    {{ $t("home.testimonialsSection.testimonial1.text") }}
                  </p>
                  <h3>
                    {{ $t("home.testimonialsSection.testimonial1.name") }}
                  </h3>
                </div>
              </div>
              <div class="carousel-item">
                <div class="quote-wrapper">
                  <p>
                    {{ $t("home.testimonialsSection.testimonial2.text") }}
                  </p>
                  <h3>
                    {{ $t("home.testimonialsSection.testimonial2.name") }}
                  </h3>
                </div>
              </div>
              <div class="carousel-item">
                <div class="quote-wrapper">
                  <p>
                    {{ $t("home.testimonialsSection.testimonial3.text") }}
                  </p>
                  <h3>
                    {{ $t("home.testimonialsSection.testimonial3.name") }}
                  </h3>
                </div>
              </div>
            </div>
            <ol class="carousel-indicators indicators">
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="0"
                class="active"
              ></li>
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="1"
              ></li>
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="2"
              ></li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { onMounted } from "vue";
import $ from "jquery";
import jQuery from "jquery";

onMounted(() => {
  window.scrollTo(0, 0);
  console.log("mounted");

  (function ($) {
    "use strict";

    $(function () {
      const header = $(".start-style");
      $(window).scroll(function () {
        const scroll = $(window).scrollTop();

        if (scroll >= 10) {
          header.removeClass("start-style").addClass("scroll-on");
        } else {
          header.removeClass("scroll-on").addClass("start-style");
        }
      });
    });

    //Animation

    $(document).ready(function () {
      $("body.hero-anime").removeClass("hero-anime");
    });

    //Menu On Hover

    $("body").on("mouseenter mouseleave", ".nav-item", function (e) {
      if ($(window).width() > 750) {
        const _d = $(e.target).closest(".nav-item");
        _d.addClass("show");
        setTimeout(function () {
          _d[_d.is(":hover") ? "addClass" : "removeClass"]("show");
        }, 1);
      }
    });

    //Switch light/dark

    $("#switch").on("click", function () {
      if ($("body").hasClass("dark")) {
        $("body").removeClass("dark");
        $("#switch").removeClass("switched");
      } else {
        $("body").addClass("dark");
        $("#switch").addClass("switched");
      }
    });
  })(jQuery);

  $(document).ready(function () {
    // set up hover panels
    // although this can be done without JavaScript, we've attached these events
    // because it causes the hover to be triggered when the element is tapped on a touch device
    $(".hover").hover(
      function () {
        $(this).addClass("flip");
      },
      function () {
        $(this).removeClass("flip");
      }
    );
  });

  // testimonial

  $(document).ready(function () {
    $(".testimonial .indicators li").click(function () {
      const i = $(this).index();
      const targetElement = $(".testimonial .tabs li");
      targetElement.eq(i).addClass("active");
      targetElement.not(targetElement[i]).removeClass("active");
    });
    $(".testimonial .tabs li").click(function () {
      const targetElement = $(".testimonial .tabs li");
      targetElement.addClass("active");
      targetElement.not($(this)).removeClass("active");
    });
  });

  // flipcard

  $(".circle-button").click(function () {
    const i = "." + $(this).attr("id");
    $(".card-middle" + i).slideToggle();
    $(".close" + i).toggleClass("closeRotate");
  });

  document.addEventListener("DOMContentLoaded", function () {
    const before_after = document.querySelectorAll(".before_after");

    // loop through all before and after modules
    for (let i = 0; i < before_after.length; i++) {
      const wrapper = before_after[i];
      const children = wrapper.children;

      // loop through child elements
      for (let e = 0; e < children.length; e++) {
        const child = children[e];

        // add event listener for mouse over
        if (child.classList.contains("img")) {
          child.addEventListener("mouseover", function () {
            toggleFocus(this, true);
          });
          child.addEventListener("mouseout", function () {
            toggleFocus(this, false);
          });
        }
      }
    }

    // var toggle = true for add, false for remove
    function toggleFocus(element, toggle) {
      const next = element.nextElementSibling;
      const prev = element.previousElementSibling;

      // add focus class to hovered element
      element.classList.toggle("focus", toggle);

      // add unfocus class to adjacent element
      if (next && next.classList.contains("img")) {
        next.classList.toggle("unfocus", toggle);
      }
      if (prev && prev.classList.contains("img")) {
        prev.classList.toggle("unfocus", toggle);
      }
    }
  });
});
</script>
