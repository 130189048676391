<template>
  <link
    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
    rel="stylesheet"
  />
  <div v-if="!$route.meta.layout">
    <NavBar />
    <router-view />
    <NavFooter />
  </div>
  <div v-else>
    <router-view />
  </div>
</template>

<style lang="scss">
/* Please ❤ this if you like it! */
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Open+Sans:ital,wght@0,400;0,500;1,400&display=swap);

@import "~bootstrap/scss/bootstrap";

/* #Primary
================================================== */
body {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #212112;
  background-position: center;
  background-repeat: repeat;
  background-size: 7%;
  background-color: #fff;
  overflow-x: hidden;
  transition: all 200ms linear;
}

p {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
}

a {
  color: #964980;
}

h4,
.h4 {
  font-size: 1.35rem;
}

.text-primary {
  color: #964980 !important;
}

::-moz-selection {
  color: #fff;
  background-color: #8167a9;
}

/* #Navigation
================================================== */
.start-header {
  opacity: 1;
  transform: translateY(0);
  padding: 20px 0;
  box-shadow: 0 10px 30px 0 rgba(138, 155, 165, 0.15);
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.start-header.scroll-on {
  box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
  padding: 10px 0;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.start-header.scroll-on .navbar-brand img {
  height: 24px;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.navigation-wrap {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.navbar {
  padding: 0;
}

.navbar-brand img {
  height: 28px;
  width: auto;
  display: block;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.navbar-toggler {
  float: right;
  border: none;
  padding-right: 0;
}

.navbar-toggler:active,
.navbar-toggler:focus {
  outline: none;
}

.navbar-light .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 1px solid #000;
  transition: all 300ms linear;
}

.navbar-light .navbar-toggler-icon:after,
.navbar-light .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 1px;
  background-color: #000;
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}

.navbar-light .navbar-toggler-icon:after {
  top: 8px;
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg);
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;
}

.nav-link {
  color: #212121 !important;
  font-weight: 500;
  transition: all 200ms linear;
}

.nav-item:hover .nav-link {
  color: #d7667d !important;
}

.nav-item.active .nav-link {
  color: #777 !important;
}

.nav-link {
  position: relative;
  padding: 5px 0 !important;
  display: inline-block;
  margin-top: 3px;
}

.nav-item:after {
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 2px;
  content: "";
  background-color: #964980;
  opacity: 0;
  transition: all 200ms linear;
}

.nav-item:hover:after {
  bottom: 0;
  opacity: 1;
}

.nav-item.active:hover:after {
  opacity: 0;
}

.nav-item {
  position: relative;
  transition: all 200ms linear;
}

/* #Primary style
================================================== */
.bg-light {
  background-color: #fff !important;
  transition: all 200ms linear;
}

.over-hide {
  overflow: hidden;
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  margin-top: 40px;
  transform: translateY(-50%);
  z-index: 20;
}

h1 {
  font-size: 56px;
  line-height: 1.2;
  font-weight: 300;
}

h1 span {
  display: inline-block;
  transition: all 300ms linear;
  opacity: 1;
  transform: translate(0);
}

body.hero-anime h1 span:nth-child(1) {
  opacity: 0;
  transform: translateY(-20px);
}

body.hero-anime h1 span:nth-child(2) {
  opacity: 0;
  transform: translateY(-30px);
}

body.hero-anime h1 span:nth-child(3) {
  opacity: 0;
  transform: translateY(-50px);
}

body.hero-anime h1 span:nth-child(4) {
  opacity: 0;
  transform: translateY(-10px);
}

body.hero-anime h1 span:nth-child(5) {
  opacity: 0;
  transform: translateY(-50px);
}

body.hero-anime h1 span:nth-child(6) {
  opacity: 0;
  transform: translateY(-20px);
}

body.hero-anime h1 span:nth-child(7) {
  opacity: 0;
  transform: translateY(-40px);
}

body.hero-anime h1 span:nth-child(8) {
  opacity: 0;
  transform: translateY(-10px);
}

body.hero-anime h1 span:nth-child(9) {
  opacity: 0;
  transform: translateY(-30px);
}

body.hero-anime h1 span:nth-child(10) {
  opacity: 0;
  transform: translateY(-20px);
}

h1 span:nth-child(1) {
  transition-delay: 1000ms;
}

h1 span:nth-child(2) {
  transition-delay: 700ms;
}

h1 span:nth-child(3) {
  transition-delay: 900ms;
}

h1 span:nth-child(4) {
  transition-delay: 800ms;
}

h1 span:nth-child(5) {
  transition-delay: 1000ms;
}

h1 span:nth-child(6) {
  transition-delay: 700ms;
}

h1 span:nth-child(7) {
  transition-delay: 900ms;
}

h1 span:nth-child(8) {
  transition-delay: 800ms;
}

h1 span:nth-child(9) {
  transition-delay: 600ms;
}

h1 span:nth-child(10) {
  transition-delay: 700ms;
}

body.hero-anime h1 span:nth-child(11) {
  opacity: 0;
  transform: translateY(30px);
}

body.hero-anime h1 span:nth-child(12) {
  opacity: 0;
  transform: translateY(50px);
}

body.hero-anime h1 span:nth-child(13) {
  opacity: 0;
  transform: translateY(20px);
}

body.hero-anime h1 span:nth-child(14) {
  opacity: 0;
  transform: translateY(30px);
}

body.hero-anime h1 span:nth-child(15) {
  opacity: 0;
  transform: translateY(50px);
}

h1 span:nth-child(11) {
  transition-delay: 1300ms;
}

h1 span:nth-child(12) {
  transition-delay: 1500ms;
}

h1 span:nth-child(13) {
  transition-delay: 1400ms;
}

h1 span:nth-child(14) {
  transition-delay: 1200ms;
}

h1 span:nth-child(15) {
  transition-delay: 1450ms;
}

#switch,
#circle {
  cursor: pointer;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}

#switch {
  width: 60px;
  height: 8px;
  border: 2px solid #8167a9;
  border-radius: 27px;
  background: #000;
  position: relative;
  display: block;
  margin: 0 auto;
  text-align: center;
  opacity: 1;
  transform: translate(0);
  transition: all 300ms linear;
  transition-delay: 1900ms;
}

body.hero-anime #switch {
  opacity: 0;
  transform: translateY(40px);
  transition-delay: 1900ms;
}

#circle {
  position: absolute;
  top: -11px;
  left: -13px;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: #000;
}

.switched {
  border-color: #000 !important;
  background: #8167a9 !important;
}

.switched #circle {
  left: 43px;
  box-shadow: 0 4px 4px rgba(26, 53, 71, 0.25), 0 0 0 1px rgba(26, 53, 71, 0.07);
  background: #fff;
}

.nav-item .dropdown-menu {
  transform: translate3d(0, 10px, 0);
  visibility: hidden;
  opacity: 0;
  max-height: 0;
  display: block;
  padding: 0;
  margin: 0;
  transition: all 200ms linear;
}

.nav-item.show .dropdown-menu {
  opacity: 1;
  visibility: visible;
  max-height: 999px;
  transform: translate3d(0, 0px, 0);
}

.dropdown-menu {
  padding: 10px !important;
  margin: 0;
  font-size: 13px;
  letter-spacing: 1px;
  color: #212121;
  background-color: #fcfaff;
  border: none;
  border-radius: 3px;
  box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
  transition: all 200ms linear;
}

.dropdown-toggle::after {
  display: none;
}

.dropdown-item {
  padding: 3px 15px;
  color: #212121;
  border-radius: 2px;
  transition: all 200ms linear;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: #fff;
  background-color: rgba(129, 103, 169, 0.6);
}

body.dark {
  color: #fff;
  background-color: #1f2029;
}

body.dark .navbar-brand img {
  filter: brightness(100%);
}

body.dark h1 {
  color: #fff;
}

body.dark h1 span {
  transition-delay: 0ms !important;
}

body.dark p {
  color: #fff;
  transition-delay: 0ms !important;
}

body.dark .bg-light {
  background-color: #14151a !important;
}

body.dark .start-header {
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.15);
}

body.dark .start-header.scroll-on {
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
}

body.dark .nav-link {
  color: #fff !important;
}

body.dark .nav-item.active .nav-link {
  color: #999 !important;
}

body.dark .dropdown-menu {
  color: #fff;
  background-color: #1f2029;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);
}

body.dark .dropdown-item {
  color: #fff;
}

body.dark .navbar-light .navbar-toggler-icon {
  border-bottom: 1px solid #fff;
}

body.dark .navbar-light .navbar-toggler-icon:after,
body.dark .navbar-light .navbar-toggler-icon:before {
  background-color: #fff;
}

body.dark .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;
}

/* #Media
================================================== */
@media (max-width: 767px) {
  h1 {
    font-size: 38px;
  }

  .nav-item:after {
    display: none;
  }

  .nav-item::before {
    position: absolute;
    display: block;
    top: 15px;
    left: 0;
    width: 11px;
    height: 1px;
    content: "";
    border: none;
    background-color: #000;
  }

  .dropdown-toggle::after {
    position: absolute;
    display: block;
    top: 10px;
    left: -23px;
    width: 1px;
    height: 11px;
    content: "";
    border: none;
    background-color: #000;
    transition: all 200ms linear;
  }

  .dropdown-toggle[aria-expanded="true"]::after {
    transform: rotate(90deg);
    opacity: 0;
  }

  .dropdown-menu {
    padding: 0 !important;
    background-color: transparent;
    box-shadow: none;
    transition: all 200ms linear;
  }

  .dropdown-toggle[aria-expanded="true"] + .dropdown-menu {
    margin-top: 10px !important;
    margin-bottom: 20px !important;
  }

  body.dark .nav-item::before {
    background-color: #fff;
  }

  body.dark .dropdown-toggle::after {
    background-color: #fff;
  }

  body.dark .dropdown-menu {
    background-color: transparent;
    box-shadow: none;
  }
}

/* #Link to page
================================================== */
.logo {
  position: absolute;
  bottom: 30px;
  right: 30px;
  display: block;
  z-index: 100;
  transition: all 250ms linear;
}

.logo img {
  height: 26px;
  width: auto;
  display: block;
  filter: brightness(10%);
  transition: all 250ms linear;
}

.offblue {
  background: #f7faff;
  padding: 4rem 0;
}

body.dark .logo img {
  filter: brightness(100%);
}

.main-hero {
  padding-top: 100px;
}

.second-hero {
  background-image: url(assets/img/hero-bg.jpg);
  background-size: cover;
  background-position: right top;
  min-height: 550px;
  background-repeat: no-repeat;
}

section.map {
  background-image: url(assets/img/map.jpg);
  background-size: cover;
  background-position: right top;
  height: 90vh;
  background-repeat: no-repeat;
  padding-top: 75px;
}

.map-col {
  background: #fff;
  height: 90vh;
}

.main {
  display: flex;
  height: 100%;
}

.no-padding {
  padding: 0;
}

.column {
  flex: 1;
  display: flex;
}

.column-65 {
  flex: 0 0 65%;
}

.column-35 {
  flex: 0 0 35%;
  padding: 0;
  overflow: scroll;
  overflow-x: hidden;
}

.column-35 .card {
  margin-bottom: 10px;
  margin-left: 10px;
}

.inner-div {
  flex-grow: 1;
}

/* For Extra Small (xs) screens */
@media (max-width: 575px) {
  .main {
    flex-direction: column;
  }

  .column {
    width: 100%;
  }
}

/* For Small (sm) screens */
@media (min-width: 576px) and (max-width: 767px) {
  .main {
    flex-direction: column;
  }

  .column {
    width: 100%;
  }
}

/* For Medium (md) screens */
@media (min-width: 768px) and (max-width: 991px) {
  .main {
    flex-direction: column;
  }

  .column {
    width: 100%;
  }
}

.hero {
  width: 90%;
}

.round-corners {
  border-radius: 15px !important;
}

a.box {
  display: block;
  height: 250px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  background: #ffffff;
  box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  color: #000;
  margin-bottom: 20px;
}

.content {
  position: absolute;
  bottom: 0;
  right: 0;
  top: 0;
  margin: auto;
  padding: 0 3em;
  z-index: 2;
  height: 3em;
  transition: all 0.2s ease;
  text-align: left;
}

a:hover .content {
  height: 8em;
  transition: all 0.2s ease;
}

h2 {
  font-size: 2em;
  font-weight: normal;
  margin: 0 0 5px 0;
}

.content p {
  height: 0;
  opacity: 0;
  visibility: hidden;
  margin: 5px;
  line-height: 1.5em;
  text-align: left;
}

a:hover p {
  height: 3em;
  opacity: 1;
  visibility: visible;
  transition: all 0.2s ease;
}

a:hover .link {
  transition: all 0.2s ease;
}

@media only screen and (max-width: 1200px) {
  .wrapper {
    margin: 50px auto;
    width: 96%;
  }

  .wrapper .row {
    margin: 40px auto;
  }

  .wrapper span {
    font-size: 1.2em;
    font-weight: 900;
  }

  .wrapper span:after {
    content: "";
    opacity: 0.7;
    display: block;
    background: #964980;
    width: 35px;
    height: 1px;
    margin: 10px 0px;
  }
}

@media only screen and (min-width: 1200px) {
  .text p,
  .circle .wrap span {
    position: absolute;
    top: 50%;
    width: 100%;
    left: 0px;
    transform: translateY(-50%);
  }

  .wrapper {
    position: relative;
  }

  .line {
    height: 90%;
    background: graytext;
    z-index: -5;
    position: absolute;
    left: 35px;
  }

  .circle {
    width: 18%;
    float: left;
  }

  .circle .wrap {
    width: 70px;
    border-radius: 100%;
    height: 70px;
    background: #964980;
    border: 1px solid #a0a0a0;
    position: relative;
    text-align: center;
    color: #ffffff;
  }

  .circle .wrap span {
    font-size: 1em;
    opacity: 1;
  }

  .text {
    height: 100px;
    width: 75%;
    float: left;
    position: relative;
  }
}

.btn-primary {
  color: #fff;
  background-color: #964980;
  border-color: #964980;
}

.btn-primary:hover {
  color: #fff;
  background-color: #d7667d;
  border-color: #d7667d;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #964980;
  border-color: #964980;
}

.btn-primary:focus,
.btn-primary.focus {
  color: #fff;
  background-color: #964980;
  border-color: #964980;
}

.carousel-item {
  text-align: left;
}

.testimonial {
  margin: 40px;
}

.testimonial img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.testimonial .quote {
  font-size: 18px;
  font-style: italic;
  margin-bottom: 10px;
}

.testimonial .author {
  font-weight: bold;
}

.new_footer_area {
  background: #ffffff;
  border-top: solid 3px #964980;
}

.new_footer_top {
  padding: 50px 0px 50px;
  position: relative;
  overflow-x: hidden;
}

.new_footer_area .footer_bottom {
  padding-top: 15px;
  padding-bottom: 15px;
}

.footer_bottom {
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  background: #f7faff;
  padding: 27px 0px;
}

.new_footer_top .company_widget p {
  font-size: 16px;
  font-weight: 300;
  line-height: 21px;
  color: #333;
  margin-bottom: 20px;
}

.new_footer_top .company_widget .f_subscribe_two .btn_get {
  border-width: 1px;
  margin-top: 20px;
}

.btn_get_two:hover {
  background: transparent;
  color: #5e2ced;
}

.btn_get:hover {
  color: #fff;
  background: #6754e2;
  border-color: #6754e2;
  -webkit-box-shadow: none;
  box-shadow: none;
}

a:hover,
a:focus,
.btn:hover,
.btn:focus,
button:hover,
button:focus {
  text-decoration: none;
  outline: none;
}

.new_footer_top .f_widget.about-widget .f_list li a:hover {
  color: #964980;
}

.new_footer_top .f_widget.about-widget .f_list li {
  margin-bottom: 11px;
}

.f_widget.about-widget .f_list li:last-child {
  margin-bottom: 0px;
}

.f_widget.about-widget .f_list li {
  margin-bottom: 15px;
}

.f_widget.about-widget .f_list {
  margin-bottom: 0px;
}

.new_footer_top .f_social_icon a {
  width: 44px;
  height: 44px;
  line-height: 43px;
  background: transparent;
  border: 1px solid #e2e2eb;
  font-size: 24px;
}

.f_social_icon a {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  font-size: 14px;
  line-height: 45px;
  color: #858da8;
  display: inline-block;
  background: #ebeef5;
  text-align: center;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.ti-facebook:before {
  content: "\e741";
}

.ti-twitter-alt:before {
  content: "\e74b";
}

.ti-vimeo-alt:before {
  content: "\e74a";
}

.ti-pinterest:before {
  content: "\e731";
}

.btn_get_two:hover {
  background: transparent;
  color: #964980;
}

.new_footer_top .f_social_icon a:hover {
  background: #964980;
  border-color: #964980;
  color: white;
}

.new_footer_top .f_social_icon a + a {
  margin-left: 4px;
}

.new_footer_top .f-title {
  margin-bottom: 30px;
  color: #964980;
  margin-top: 20px;
}

.f_size_18 {
  font-size: 18px;
}

.new_footer_top .f_widget.about-widget .f_list li a {
  color: #333;
}

.btnline {
  background: none;
  color: #964980;
  border: solid 1px #964980;
}

.btnline:hover {
  border: solid 1px #d7667d;
  color: #fff;
  background: #d7667d;
}

.abouthero {
  position: relative;
  width: 100%;
  height: 550px;
  background: rgb(50, 50, 50);
  background: url(assets/img/aboutus-hero.jpg) no-repeat 50% 0% fixed;
  background-size: cover;
  overflow: hidden;
}

#team .card {
  border: none;
  background: #ffffff;
  box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.image-flip:hover .backside,
.image-flip.hover .backside {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  transform: rotateY(0deg);
  border-radius: 0.25rem;
}

.image-flip:hover .frontside,
.image-flip.hover .frontside {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.mainflip {
  -webkit-transition: 1s;
  -webkit-transform-style: preserve-3d;
  -ms-transition: 1s;
  -moz-transition: 1s;
  -moz-transform: perspective(1000px);
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transition: 1s;
  transform-style: preserve-3d;
  position: relative;
}

.frontside {
  position: relative;
  -webkit-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  z-index: 2;
  margin-bottom: 30px;
}

.backside {
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  -webkit-transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg);
  -o-transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.frontside,
.backside {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: 1s;
  -webkit-transform-style: preserve-3d;
  -moz-transition: 1s;
  -moz-transform-style: preserve-3d;
  -o-transition: 1s;
  -o-transform-style: preserve-3d;
  -ms-transition: 1s;
  -ms-transform-style: preserve-3d;
  transition: 1s;
  transform-style: preserve-3d;
}

.frontside .card,
.backside .card {
  min-height: 312px;
}

.backside .card a {
  font-size: 18px;
  color: #d7667d !important;
}

.frontside .card .card-title,
.backside .card .card-title {
  color: #333 !important;
}

.cardMateri {
  background-color: #f1f6e3;
  border-radius: 8px;
  -webkit-box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.21);
  -moz-box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.21);
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.21);
}

#accordion .panel {
  border: none;
  border-radius: 3px;
  box-shadow: none;
}

#accordion .panel-heading {
  padding: 1px;
  border: none;
  border-radius: 3px;
}

#accordion .panel-title a {
  display: block;
  padding: 15px 20px;
  background: #fff;
  color: #964980;
  text-decoration: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  position: relative;
  transition: all 0.5s ease 0s;
  border-radius: 5px;
}

#accordion .panel-title a.collapsed {
  box-shadow: none;
  color: #676767;
  text-decoration: none;
  box-shadow: 0 1px 2px rgba(43, 59, 93, 0.3);
}

#accordion .panel-title a:before,
#accordion .panel-title a.collapsed:before {
  content: "\f067";
  font-family: "FontAwesome";
  width: 25px;
  height: 25px;
  line-height: 28px;
  font-weight: 900;
  color: #964980;
  text-align: center;
  position: absolute;
  top: 12px;
  right: 15px;
  transform: rotate(135deg);
  transition: all 0.3s ease 0s;
}

#accordion .panel-title a.collapsed:before {
  transform: rotate(0);
}

#accordion .panel-title a:after {
  content: "";
  width: 1px;
  height: 100%;
  background: #ececec;
  position: absolute;
  top: 0;
  right: 55px;
}

#accordion .panel-body {
  border: none;
  background-color: #fff;
}

#accordion .panel-body .isiMateri {
  padding: 25px;
  margin-bottom: 10px;
}

/* dekstop */
@media screen and (min-width: 1024px) {
  .paddingLuar,
  .cardMateri {
    padding: 2rem 0;
  }

  .judulUtama {
    font-size: 24px;
    font-weight: 500;
    text-align: left;
    margin-bottom: 1.5rem;
  }

  .isiMateri p:nth-child(1) {
    font-size: 16px;
    margin-bottom: 1rem;
    text-align: left;
  }

  .isiMateri p:nth-child(2) {
    font-size: 15px;
    text-align: justify;
  }

  #accordion .panel-title a {
    font-size: 16px;
  }

  #accordion .panel-title a:before,
  #accordion .panel-title a.collapsed:before {
    font-size: 16px;
  }
}

/* tablet */
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .paddingLuar,
  .cardMateri {
    padding: 2rem;
  }

  .judulUtama {
    font-size: 16px;
    text-align: left;
    margin-bottom: 1rem;
  }

  .isiMateri p:nth-child(1) {
    font-size: 16px;
    margin-bottom: 0.75rem;
    text-align: left;
  }

  .isiMateri p:nth-child(2) {
    font-size: 14px;
    text-align: justify;
  }

  #accordion .panel-title a {
    font-size: 16px;
  }

  #accordion .panel-title a:before,
  #accordion .panel-title a.collapsed:before {
    font-size: 13px;
  }
}

/* HP */
@media screen and (max-width: 767px) {
  .paddingLuar,
  .cardMateri {
    padding: 1rem;
  }

  .judulUtama {
    font-size: 16px;
    text-align: left;
    margin-bottom: 1rem;
  }

  .isiMateri p:nth-child(1) {
    font-size: 16px;
    margin-bottom: 0.5rem;
    text-align: left;
  }

  .isiMateri p:nth-child(2) {
    font-size: 12px;
    text-align: left;
  }

  #accordion .panel-title a {
    font-size: 13px;
    padding-right: 45px;
    padding-left: 8px;
  }

  #accordion .panel-title a:before,
  #accordion .panel-title a.collapsed:before {
    font-size: 11px;
  }
}

.testimonial {
  padding: 15px 0;
}

.testimonial .row .tabs {
  all: unset;
  margin-right: 50px;
  display: flex;
  flex-direction: column;
}

.testimonial .row .tabs li {
  all: unset;
  display: block;
  position: relative;
}

.testimonial .row .tabs li.active::before {
  position: absolute;
  content: "";
  width: 50px;
  height: 50px;
  background-color: #d7667d;
  border-radius: 50%;
}

.testimonial .row .tabs li.active::after {
  position: absolute;
  content: "";
  width: 30px;
  height: 30px;
  background-color: #d7667d;
  border-radius: 50%;
}

.testimonial .row .tabs li:nth-child(1) {
  align-self: flex-end;
}

.testimonial .row .tabs li:nth-child(1)::before {
  left: 64%;
  bottom: -50px;
}

.testimonial .row .tabs li:nth-child(1)::after {
  left: 97%;
  bottom: -81px;
}

.testimonial .row .tabs li:nth-child(1) figure img {
  margin-left: auto;
}

.testimonial .row .tabs li:nth-child(2) {
  align-self: flex-start;
}

.testimonial .row .tabs li:nth-child(2)::before {
  right: -80px;
  top: 50%;
}

.testimonial .row .tabs li:nth-child(2)::after {
  bottom: 101px;
  border-radius: 50%;
  right: -120px;
}

.testimonial .row .tabs li:nth-child(2) figure img {
  margin-right: auto;
  min-width: 300px;
  width: 100%;
  margin-top: -50px;
  min-height: 300px;
}

.testimonial .row .tabs li:nth-child(3) {
  align-self: flex-end;
}

.testimonial .row .tabs li:nth-child(3)::before {
  right: -10px;
  top: -66%;
}

.testimonial .row .tabs li:nth-child(3)::after {
  top: -130px;
  border-radius: 50%;
  right: -46px;
}

.testimonial .row .tabs li:nth-child(3) figure img {
  margin-left: auto;
  margin-top: -20px;
}

.testimonial .row .tabs li:nth-child(3):focus {
  border: 10px solid #964980;
}

.testimonial .row .tabs li figure {
  position: relative;
}

.testimonial .row .tabs li figure img {
  display: block;
}

.testimonial .row .tabs li figure::after {
  content: "";
  position: absolute;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  border: 4px solid #d7667d;
  border-radius: 50%;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.testimonial .row .tabs li figure:hover::after {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.testimonial .row .tabs.carousel-indicators li.active figure::after {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.testimonial .row .carousel > h3 {
  font-size: 18px;
  line-height: 1.45;
  margin-bottom: 0;
}

.testimonial .row .carousel h1 {
  line-height: 1.225;
  margin-top: 21px;
  margin-bottom: 0;
}

.testimonial .row .carousel .carousel-indicators {
  all: unset;
  padding-top: 43px;
  display: flex;
  list-style: none;
}

.testimonial .row .carousel .carousel-indicators li {
  background: #964980;
  background-clip: padding-box;
  height: 3px;
}

.testimonial .row .carousel .carousel-inner .carousel-item .quote-wrapper {
  margin-top: 42px;
}

.testimonial .row .carousel .carousel-inner .carousel-item .quote-wrapper p {
  line-height: 1.5;
}

.testimonial .row .carousel .carousel-inner .carousel-item .quote-wrapper h3 {
  color: #000;
  margin-top: 35px;
  font-size: 14px;
  line-height: 1.45;
  text-transform: uppercase;
}

@media only screen and (max-width: 1200px) {
  .testimonial .row .tabs {
    margin-right: 25px;
  }
}

.card {
  position: relative;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  border-radius: 0.75em;
  padding: 0;
  transition: all 0.2s ease-in-out;
  margin-bottom: 20px;
}

/* .card:nth-child(even) {
    margin: 0 1em;
} */
.card-top {
  background: #f7faff;
  padding: 1.25em;
  border-top-left-radius: 0.75em;
  border-top-right-radius: 0.75em;
}

.card-top .card-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 12em;
  width: auto;
}

.card-top .overlay {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 14.1em;
  width: 100%;
  border-top-left-radius: 0.75em;
  border-top-right-radius: 0.75em;
  z-index: 10;
  background: rgba(0, 0, 0, 0.2);
  overflow: hidden;
  transition: all 700ms ease;
  opacity: 0;
}

.card-top .overlay:hover,
.card-top .overlay:focus {
  opacity: 1;
}

.triangle {
  position: relative;
  height: 0;
  width: 0;
  border-left: 1.25em solid transparent;
  border-right: 1.25em solid transparent;
  border-top: 1.25em solid #f7faff;
  margin: 0 auto -1.25em auto;
  top: -0.25em;
  z-index: 20;
}

.card-middle {
  display: none;
  background: #ffffff;
  padding: 2em 1em 1em 1em;
}

.card-middle .more {
  font-family: Open Sans;
  font-weight: 400;
}

.card-bottom {
  background: #ffffff;
  color: #964980;
  padding: 1.75em 1em 1em 1em;
  border-bottom-left-radius: 0.75em;
  border-bottom-right-radius: 0.75em;
}

.circle-button {
  position: absolute;
  bottom: -5px;
  right: 3.5em;
  width: 50px;
  height: 50px;
  font-size: 1.5em;
  color: #fff;
  background: #fff;
  border: none;
  border-radius: 50%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.275);
  outline: none;
  cursor: pointer;
  transition: all 300ms ease;
}

.circle-button .close {
  transition: transform 400ms ease;
}

.circle-button:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.275);
}

.closeRotate {
  transform: rotate(45deg);
}
</style>
<script setup type="ts">
/* eslint-disable @typescript-eslint/no-unused-vars */
import NavBar from "./components/NavBar.vue";
import NavFooter from "./components/NavFooter.vue";

import { onMounted } from "vue";
import $ from "jquery";
import jQuery from "jquery";

onMounted(() => {

  (function ($) {
    "use strict";

    $(function () {
      const header = $(".start-style");
      $(window).scroll(function () {
        const scroll = $(window).scrollTop();

        if (scroll >= 10) {
          header.removeClass("start-style").addClass("scroll-on");
        } else {
          header.removeClass("scroll-on").addClass("start-style");
        }
      });
    });

    //Animation

    $(document).ready(function () {
      $("body.hero-anime").removeClass("hero-anime");
    });

    //Menu On Hover

    $("body").on("mouseenter mouseleave", ".nav-item", function (e) {
      if ($(window).width() > 750) {
        const _d = $(e.target).closest(".nav-item");
        _d.addClass("show");
        setTimeout(function () {
          _d[_d.is(":hover") ? "addClass" : "removeClass"]("show");
        }, 1);
      }
    });

    //Switch light/dark

    $("#switch").on("click", function () {
      if ($("body").hasClass("dark")) {
        $("body").removeClass("dark");
        $("#switch").removeClass("switched");
      } else {
        $("body").addClass("dark");
        $("#switch").addClass("switched");
      }
    });
  })(jQuery);

  $(document).ready(function () {
    // set up hover panels
    // although this can be done without JavaScript, we've attached these events
    // because it causes the hover to be triggered when the element is tapped on a touch device
    $(".hover").hover(
      function () {
        $(this).addClass("flip");
      },
      function () {
        $(this).removeClass("flip");
      }
    );
  });

  // testimonial

  $(document).ready(function () {
    $(".testimonial .indicators li").click(function () {
      const i = $(this).index();
      const targetElement = $(".testimonial .tabs li");
      targetElement.eq(i).addClass("active");
      targetElement.not(targetElement[i]).removeClass("active");
    });
    $(".testimonial .tabs li").click(function () {
      const targetElement = $(".testimonial .tabs li");
      targetElement.addClass("active");
      targetElement.not($(this)).removeClass("active");
    });
  });

  // flipcard

  $("#circleDrop").click(function () {
    $(".card-middle").slideToggle();
    $(".close").toggleClass("closeRotate");
  });
});
</script>
